import { useCallback, useEffect } from 'react';
import Cookies from 'nookies';

import { partialObjectEquals } from 'Utils/gen';
import { getNakedDomain } from 'Utils/urlUtils';

import { COOKIE, QUERY_PARAM, TIME } from 'Constants/constants';

type THOAttribution = {
	ci?: string | null; // Channel Id
	cm?: string | null; // Channel Metadata
	ref?: string; // Referrer
	lp: string; // Landing Page
	ts: number; // Timestamp
};

const MAX_ATTRIBUTION_LENGTH = 20;
const BLACK_LISTED_ROUTES = ['/book/'];
const CM_SEPARATOR = '_';

const useAttribution = () => {
	const onAttemptAttribution = useCallback(() => {
		const cookies = Cookies.get();
		const { search, host, href } = location;
		const { referrer } = document;
		const [queryStrippedHref] = href.split('?');
		const searchParams = new URLSearchParams(search ?? '?');
		const ci = searchParams.get(QUERY_PARAM.ATRIBUTION_CHANNEL_ID);
		const cm = searchParams.get(QUERY_PARAM.ATRIBUTION_CHANNEL_META);
		const utmSource = searchParams.get(QUERY_PARAM.UTM_SOURCE);
		const utmContent = searchParams.get(QUERY_PARAM.UTM_CONTENT);
		const utmCampaign = searchParams.get(QUERY_PARAM.UTM_CAMPAIGN);
		const utmTerm = searchParams.get(QUERY_PARAM.UTM_TERM);
		const affiliateCode = searchParams.get(QUERY_PARAM.AFFILIATE_CODE);
		const googleCampaignId = searchParams.get(
			QUERY_PARAM.GOOGLE_CAMPAIGN_ID,
		);
		const facebookCampaignId = searchParams.get(
			QUERY_PARAM.FACEBOOK_CAMPAIGN_ID,
		);
		const bingCampaignId = searchParams.get(QUERY_PARAM.BING_CAMPAIGN_ID);

		const currentHost = getNakedDomain(host);
		/**
		 * PS: is likely direct traffic, not guaranteed.
		 * no-follow config and/or privacy centric browsers.
		 */
		const isDirectTraffic = !referrer?.length;
		const isInlinkTraffic = referrer?.includes(currentHost);
		const campaignIdsMap = {
			...(googleCampaignId && {
				[QUERY_PARAM.GOOGLE_CAMPAIGN_ID]: googleCampaignId,
			}),
			...(facebookCampaignId && {
				[QUERY_PARAM.FACEBOOK_CAMPAIGN_ID]: facebookCampaignId,
			}),
			...(bingCampaignId && {
				[QUERY_PARAM.BING_CAMPAIGN_ID]: bingCampaignId,
			}),
		};

		if (
			BLACK_LISTED_ROUTES.some(partialRoute =>
				href.includes(partialRoute),
			)
		)
			return;

		/**
		 * Attempt to restore cookie.
		 */
		let currentAttr: Array<THOAttribution> = [];
		try {
			currentAttr = JSON.parse(
				cookies[COOKIE.HEADOUT_ATTRIBUTION_TRACKER],
			);
		} catch (e) {
			currentAttr = [];
		}
		const originalAttrLen = currentAttr.length;
		const recentMostAttr = currentAttr[originalAttrLen - 1];

		switch (true) {
			// Legacy Affiliate Traffic
			case !!affiliateCode && !ci?.length: {
				const affiliateType = utmSource?.includes('impact')
					? 'impact'
					: 'internal';
				const linkSource = utmContent || 'null'; // link/gallery/calendar | widget types
				const campaign = utmCampaign || 'null';
				const keyword = utmTerm || 'null';
				currentAttr.push({
					ci: '4',
					cm: [
						affiliateCode || '',
						affiliateType,
						linkSource,
						campaign,
						keyword,
					]
						.map(string => string.replace(/_/gi, '-'))
						.join(CM_SEPARATOR), // EK3iDg _ internal _ gallery _ Eiffel-Tower _ null
					ts: Date.now(),
					...(referrer && { ref: referrer }),
					lp: queryStrippedHref,
				});
				break;
			}
			// external traffic
			case !isInlinkTraffic && !!ci?.length:
				currentAttr.push({
					ci,
					...(referrer && { ref: referrer }),
					...(cm && { cm }),
					lp: queryStrippedHref,
					ts: Date.now(),
					...campaignIdsMap,
				});
				break;

			// Direct Traffic but has some channel identifers.
			// or referrer hidden / blocked.
			case isDirectTraffic && (!!ci?.length || !!cm?.length):
				currentAttr.push({
					...(ci && { ci }),
					...(cm && { cm }),
					lp: queryStrippedHref,
					ts: Date.now(),
					...campaignIdsMap,
				});
				break;

			// External Legacy Traffic. (no ci && cm)
			case !isInlinkTraffic && !isDirectTraffic:
				currentAttr.push({
					lp: queryStrippedHref,
					ts: Date.now(),
					ref: referrer,
					...campaignIdsMap,
				});
				break;

			// Default case, is external traffic
			case !isInlinkTraffic && isDirectTraffic:
				currentAttr.push({
					lp: queryStrippedHref,
					ts: Date.now(),
					...campaignIdsMap,
				});
		}

		if (originalAttrLen !== currentAttr.length) {
			const newAttribution = currentAttr[currentAttr.length - 1];

			// If newAttr is identical to recentMostAttr, remove old entry.
			if (
				recentMostAttr &&
				partialObjectEquals(newAttribution, recentMostAttr, ['ts'])
			) {
				currentAttr.splice(-2, 2);
				currentAttr.push({
					...newAttribution,
				});
			}
			// Keep only the recent most MAX_ATTRIBUTION_LENGTH attributions.
			currentAttr.splice(0, currentAttr.length - MAX_ATTRIBUTION_LENGTH);

			Cookies.set(
				{},
				COOKIE.HEADOUT_ATTRIBUTION_TRACKER,
				JSON.stringify(currentAttr),
				{
					path: '/',
					domain: currentHost,
					expires: new Date(Date.now() + TIME.IN_DAYS * 31),
				},
			);
		}
	}, []);

	useEffect(() => {
		try {
			onAttemptAttribution();
		} catch (e) {
			//
		}
	}, [onAttemptAttribution]);
};

export default useAttribution;
