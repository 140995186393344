/**
 * Generic constants used throughout
 */
import { ApplePaySvg } from 'Assets/svg/checkout/ApplePaySvg';
import { GooglePaySvg } from 'Assets/svg/checkout/GooglePaySvg';
import { AffirmSvg } from 'Assets/svg/payment/AffirmSvg';
import { CardSvg } from 'Assets/svg/payment/CardSvg';
import { FPXSvg } from 'Assets/svg/payment/FPX';
import { IDealSvg } from 'Assets/svg/payment/IDealSvg';
import { PayPalSvg } from 'Assets/svg/payment/PayPalSvg';

import { strings } from './strings';

// @NOTE: IMPORTANT. If you are changing the versions.
// All of our users current store data is cleared. We need to do this ONLY when
// previous store schema is not supported anymore. not for additions.
// Make sure you are clear on that before bumping up the number
export const STORE_JS_VERSION = '0.2.3';

export const DATA_SOURCE_TYPE = {
	API: 'API',
	STORE: 'STORE',
	LOCAL_STORAGE: 'LOCAL_STORAGE',
};

export const PRICE_RANGE_DELIMETER = '~';

export const PRICE_FETCH_STATUS = {
	IN_PROGRESS: 'IN_PROGRESS',
	NOT_FETCHED: 'NOT_FETCHED',
	COMPLETE: 'COMPLETE',
};

export const TOUR_TYPE = {
	TOUR: 'TOUR',
	ACTIVITY: 'ACTIVITY',
	TRANSFER: 'TRANSFER',
	EVENT: 'EVENT',
	ATTRACTION: 'ATTRACTION',
	ONE_TIME: 'ONE_TIME',
};

export const DROPDOWN_TYPES = {
	CUSTOMER_FIELD: 'Customer Field',
	PAYMENT_OPTIONS: 'Payment Options',
	PAYMENT_FIELD: 'Payment Field',
};

export const STORE_ENTITY_TYPE = {
	CITIES_LIST: 'CITIES_LIST',
	CURRENT_CITY_CODE: 'CURRENT_CITY_CODE',
	CURRENT_CURRENCY: 'CURRENT_CURRENCY',
	LOCATION_CURRENCY: 'LOCATION_CURRENCY',
	CART_ABANDONMENT_TRACKER: 'CART_ABANDONMENT_TRACKER',
	PRODUCT_LIST: 'PRODUCT_LIST',
	CATEGORIES_LIST: 'CATEGORIES_LIST',
	PRODUCT: 'PRODUCT',
	BOOKINGS: 'BOOKINGS',
	SEAT_SVG: 'SEAT_SVG',
	USER: 'USER',
	STORE_VERSION: 'STORE_VERSION',
};

export const BANNER_IMAGE_SIZE = {
	DESKTOP: {
		HEIGHT: 500,
		WIDTH: 1200,
	},
	MOBILE: {
		HEIGHT: 400,
		WIDTH: 343,
	},
};

export const PRODUCT_BANNER_SIZE = {
	DESKTOP: {
		HEIGHT: 450,
		WIDTH: 720,
	},
	MOBILE: {
		HEIGHT: 310,
		WIDTH: 496,
	},
};

export const BOOKING_FLOW_TYPE = {
	SEATMAP: 'SEATMAP',
	SVG: 'SVG',
	COMBO: 'COMBO',
	NORMAL: 'NORMAL',
	RESERVATION: 'RESERVE',
	OPEN_DATED: 'OPEN_DATED',
	PRIVATE_AIRPORT_TRANSFER: 'PRIVATE_AIRPORT_TRANSFER',
	SHARED_AIRPORT_TRANSFER: 'SHARED_AIRPORT_TRANSFER',
	HOP_ON_HOP_OFF: 'HOP_ON_HOP_OFF',
	PROPERTY_SELECTION: 'PROPERTY_SELECTION',
	GUIDED_TOUR_PROPERTY_SELECTION: 'GUIDED_TOUR_PROPERTY_SELECTION',
};

export const BOOKING_FLOW_STAGE = {
	CONTENT: 'content',
	SELECT: 'select',
	CHECKOUT: 'checkout',
	COMBO_UPSELL: 'upgrade-your-experience',
	/* SVG FLOW */
	SVG_SELECT: 'svg-select',
	SVG_VARIANT: 'svg-variant',
	/* SEATMAP FLOW */
	EXTERNAL_SEATMAP_SELECT: 'seatmap-iframe-select',
	SEATMAP_SELECT: 'seatmap-select',
	SEATMAP_VARIANT: 'seatmap-variant',
	SEATMAP_CHECKOUT: 'seatmap-checkout',
	/* POST-CHECKOUT */
	CONFIRMATION: 'confirmation',
	/* SEPARATED-ROUTES */
	RECOMMENDATIONS: 'recommendations',
};

export const POST_CHECKOUT_STAGES = [BOOKING_FLOW_STAGE.CONFIRMATION];

export const TGID_INDEPENDENT_BOOKING_FLOW_STAGES = [
	BOOKING_FLOW_STAGE.RECOMMENDATIONS,
	BOOKING_FLOW_STAGE.CHECKOUT,
];

export const BOOKING_FLOW_CHECKOUT_STAGES = [
	BOOKING_FLOW_STAGE.CHECKOUT,
	BOOKING_FLOW_STAGE.SEATMAP_CHECKOUT,
];

export const BOOKING_FLOW_TOP_LEVEL_ROUTES = ['book', ...POST_CHECKOUT_STAGES];

export const BOOKING_FLOW_SUBSTAGE = {
	TIME: 'time',
	PAX: 'pax',
	RESERVATION_DETAILS: 'reservation-details',
};

export const BOOKING_STATUS = {
	ON_REQUEST: 'ON_REQUEST',
	PENDING: 'PENDING',
	SUCCESSFUL: 'SUCCESSFUL',
	ERROR: 'ERROR',
	UNCAPTURED: 'UNCAPTURED',
};

export const ORDER_STATUS_TYPES = {
	PENDING: 'PENDING',
	COMPLETED: 'COMPLETED',
	CANCELLED: 'CANCELLED',
	UNCAPTURED: 'UNCAPTURED',
};

export const PAYMENT_POLLING_STATE = {
	CONTINUE: 'CONTINUE',
	STOP: 'STOP',
};

export const PAGE_TYPE = {
	HOME: 'Home Page',
	CITY: 'City Page',
	CITY_CONTENT: 'City Content Page',
	PERSONA: 'Persona Page',
	SEARCH_LIST: 'Search Results Page',
	TOUR_LIST: 'TOUR_LIST',
	COLLECTION: 'Collection Page',
	DAY_TRIPS_COLLECTION: 'Day Trips Collection Page',
	CATEGORY: 'Category Page',
	SUB_CATEGORY: 'Sub Category Page',
	CATEGORY_TAB: 'Category Tab Page',
	EXPERIENCE: 'Experience Page',
	REVIEW: 'Review Page',
	CITIES_SITEMAP: 'Cities Sitemap Page',
	COLLECTIONS_SITEMAP: 'Collections Sitemap Page',
	PRODUCT_CARD: 'Product Card Page',
	PRODUCT_CARD_LIST: 'Product Card List Page',
	ORDER_STATUS: 'Order Status Page',
	ABOUT_US: 'About Us Page',
	ACCOUNT: 'Account Page',
	PRIVACY_POLICY: 'Privacy Policy Page',
	TERMS_OF_USE: 'Terms Of Use Page',
	CANCELLATION_POLICY: 'Cancellation Policy Page',
	APP_SHOWCASE: 'App Showcase Page',
	EMBED_CREATE: 'Embed Create Page',
	HELP: 'Help Page',
	BOOKING: 'Booking Page',
	SELECT: 'Select Page',
	TOURS: 'Tours Page',
	CHECKOUT: 'Checkout Page',
	ORDER_CONFIRMATION: 'Bookings Confirmation Page',
	REFERRAL: 'Referral Page',
	SCHOLARSHIP: 'Scholarship Page',
	ERROR: 'Error Page',
	NOT_FOUND: 'Page Not Found',
	PLACES_TO_VISIT: 'Places to visit Page',
	SEATS: 'Seats Page',
	STUDENT_GRADUATE_DISCOUNT: 'Student & Graduate Discount Page',
	VOUCHER: 'Vouchers',
	MANAGE_BOOKING: 'Manage Booking',
	COMBO_UPSELL: 'Combo Upsell',
	APP_PUSH_PAGE: 'App Download Nudge Page',
	RECOMMENDATIONS: 'Recommendations Page',
};

export const COLLECTION_TYPE = {
	POI: 'POI',
	DAY_TRIPS: 'Day Trips',
};

export const CATEGORY_TYPE = {
	CATEGORY: 'Category',
	SUB_CATEGORY: 'Sub-Category',
};

export const PROFILE_TYPE = {
	PER_PERSON: 'PER_PERSON',
	PER_GROUP: 'PER_GROUP',
} as const;

export const NODE_ENV = {
	DEV: 'development',
	TEST: 'test',
	STAGE: 'stage',
	PROD: 'production',
	ONDEMAND: 'ondemand',
};

export const CDN_IMGIX_BASE_SERVER = 'cdn-imgix.headout.com';

export const CDN_IMGIX_OPEN_BASE_SERVER = 'cdn-imgix-open.headout.com';

export const CDN_IMGIX_PRISMIC = 'images.prismic.io';

export const INVENTORY_TYPE = {
	FIXED_START_FIXED_DURATION: 'FIXED_START_FIXED_DURATION',
	FIXED_START_FLEXIBLE_DURATION: 'FIXED_START_FLEXIBLE_DURATION',
	FLEXIBLE_START_FIXED_DURATION: 'FLEXIBLE_START_FIXED_DURATION',
	FLEXIBLE_START_FLEXIBLE_DURATION: 'FLEXIBLE_START_FLEXIBLE_DURATION',
};

export const INVENTORY_START_TYPE = {
	FIXED_START: 'FIXED_START',
	FLEXIBLE_START: 'FLEXIBLE_START',
};

export const INVENTORY_DURATION_TYPE = {
	FIXED_DURATION: 'FIXED_DURATION',
	FLEXIBLE_DURATION: 'FLEXIBLE_DURATION',
};

export const AVAILABILITY_TYPE = {
	LIMITED: 'LIMITED',
	UNLIMITED: 'UNLIMITED',
};

export const PAX_TYPES = {
	GROUP: 'GROUP',
	ADULT: 'ADULT',
};

export const TIME = {
	SECONDS_IN_YEARS: 365 * 24 * 60 * 60,
	SECONDS_IN_MONTH: 24 * 60 * 60 * 30,
	SECONDS_IN_DAY: 24 * 60 * 60,
	SECONDS_IN_HOUR: 60 * 60,
	SECONDS_IN_MINUTE: 60,
	IN_YEARS: 365 * 24 * 60 * 60 * 1000,
	IN_DAYS: 24 * 60 * 60 * 1000,
	IN_HOURS: 60 * 60 * 1000,
	IN_MINUTES: 60 * 1000,
	IN_SECONDS: 1000,
};

export const DEVICE_TYPE = {
	DESKTOP: 'DESKTOP',
	MOBILE: 'MOBILE',
	COMMON: 'COMMON',
};

export const EVENTS = {
	WINDOW: {
		MESSAGE: 'message',
	},
};

export const GTM_KEY_PROD = {
	COMMON: 'GTM-NPMNPB',
};

export const GTM_KEY_TEST = {
	COMMON: 'GTM-5NXSKCP',
};

export const GTM_AUTH_DEV = '8pwO-VJc8ITZdfyLd4gB9g';
export const GTM_AUTH_PROD = 'gjKqx84Vs2f3zwF3HUVSdg';
export const GTM_PREVIEW_DEV = 'env-305';
export const GTM_PREVIEW_PROD = 'env-1';

export const GTM_KEY_EXT = {
	LTD: 'GTM-KRXWM4G',
	BRITISH_THEATRE: 'GTM-MVRNRPH4',
};

export const SMARTLOOK_KEY = '17ca818c8a439f21ae5591ffe88899bdd1b690e3';

export const GCLOUD_KEY = {
	PROD: 'AIzaSyASJdYx_PaF1NYURepT0FuEgqJIgy0wt30',
	TEST: 'AIzaSyAOiMzGkoNkmH9nBDyXxBi_HMEcVQEDyAI',
	DEV: 'AIzaSyBmvsC5WXgrpLMSYDt7VCF1IiIKpUQS818',
};

export const AMPLITUDE_KEY = {
	PROD: '0d0bf3b04d91a9a79926398eca5cac45',
	SANDBOX: '9d33325a1028921e1dc2174dc0527722',
};

const FINGERPRINT_PUBLIC_API_KEY = {
	LIVE: 'FKrnFQBPaWEB22nmjlmq',
	TEST: 'BFaaW5eyryIPB37yAwdm',
} as const;

export const FINGERPRINT_CONFIG = {
	LIVE: {
		PUBLIC_KEY: FINGERPRINT_PUBLIC_API_KEY.LIVE,
		SCRIPT_PATTERN: `https://metrics.headout.com/web/v3/${FINGERPRINT_PUBLIC_API_KEY.LIVE}/loader_v3.8.1.js`,
		ENDPOINT: 'https://metrics.headout.com',
	},
	TEST: {
		PUBLIC_KEY: FINGERPRINT_PUBLIC_API_KEY.TEST,
		SCRIPT_PATTERN: `https://fpjscdn.net/v3/${FINGERPRINT_PUBLIC_API_KEY.TEST}/loader_v3.8.1.js`,
		ENDPOINT: 'https://fpjscdn.net',
	},
} as const;

export const BANNER_NON_CITY_KEY = 'NON_CITY_SPECIFIC';

export const OPENGRAPH_VIDEO_URL =
	'https://cdn-imgix-open.headout.com/opengraph/headout.mp4';

export const BANNER_HOMEPAGE = {
	imageUrl:
		'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/headout-default-banner-desktop.png',
	redirectUrl: '',
};

export const BANNER_HOMEPAGE_MOBILE = {
	imageUrl:
		'https://cdn-imgix-open.headout.com/flaps/non-city-specific/web/headout-default-banner-mobile-1.png',
	alt: '',
	imageHtml: '',
	redirectUrl: '',
};

export const BANNER_IMAGE_URLS = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/headout-default-banner-desktop.png',
];

export const BANNER_IMAGE_URLS_CITY_PAGE_DESKTOP = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/headout-default-banner-desktop-1.png',
];

export const BANNER_IMAGE_URLS_MOBILE = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/web/headout-default-banner-mobile.png',
];

export const BANNER_IMAGE_URLS_CITY_PAGE_MOBILE = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/web/headout-default-banner-mobile-1.png',
];

export const ACTIVITY_TYPES = {
	KEY_PRESS: 'Key Press',
	SCROLL: 'Scroll',
	CLICK: 'Click',
	TAP: 'Tap',
	MOUNT: 'Mount',
	BLUR: 'Blur',
	FOCUS: 'Focus',
};

export const COOKIE = {
	GOOGLE_TRANSLATE: 'googtrans',
	CURRENT_CITY_CODE: 'currentCityCode',
	CURRENT_CURRENCY: 'currentCurrency',
	LOCATION_CURRENCY: 'locationCurrency',
	IS_SIGNED_IN: 'is-signed-in',
	HEADOUT_ACCESS_TOKEN: 'h-at',
	CONCIERGE_USER_TOKEN: 'user.token',
	USER_PROFILE_TYPE: 'user-profile-type',
	SANDBOX_ID: 'h-sid',
	CURRENT_CHANNEL: 'currentChannel',
	IS_DISPLAY_TRAFFIC: 'isDisplayTraffic',
	LOCATION_POPUP: 'locationPopup',
	CONTENT_LANG: 'content_lang',
	USER_AGENT: 'user-agent',
	REDIRECTION_TO_NEXT_JS: 'redirection_to_nextjs',
	DEBUG_MODE: 'debug_mode',
	ORIGINAL_HOST_URL: 'original_host_url',
	BOOKING_FLOW_SERVER_REDIRECTION: 'booking-flow-server-redirection',
	AFFILIATE_CODE: 'affiliate_code',
	AFFILIATE_UTM_INFO: 'affiliate_utm_info',
	TRAVELPAYOUTS_SUB_ID: 'sub_id',
	TIME_URGENCY_SHOWN: 'time-urgency-shown',
	CLARITY_PROJECT_ID: 'clarity-pid',
	LOGIN_SUCCESS_TRACKER: 'magic-signin-success',
	CASHBACK_EXP_VIEWED: 'cshbck-exp',
	IMPACT_CLICK_ID: 'irclickid',
	EXPERIMENT_OVERRIDE: 'experimentOverride',
	LIVE_INVENTORY: 'live-inventory',
	CONSENT_POLICY_STATE: 'consent-state',
	HEADOUT_ATTRIBUTION_TRACKER: 'h-attr',
	TTD_TGIDS: 'ttd-tgids',
	PARTNER_TRACE_ID: 'partner-trace-id',
	USER_PREFERENCES: 'user-preferences',
	COUNTRY_CODE: 'countryCode',
};

export const PROMOTION_TYPES = {
	TTD: 'ttd',
	BAC: 'bac',
} as const;

export const IMPACT_AFFILIATE_CODE = 'V8ODIn';

export const CUSTOM_HEADER = {
	ORIGIN: 'h-origin',
	AFFILIATE_CODE: 'affiliate-code',
	FORWARDED_COUNTRY_CODE: 'X-Forwarded-Country-Code',
	PARTNER_TRACE_ID: 'partner-trace-id',
	HEADOUT_FEES: 'X-Headout-Fee-Experiment-Bucket',
	FINGERPRINT: 'x-headout-fingerprint',
	TGID: 'X-headout-tgid',
	CHANNEL: 'x-channel',
};

export const CHANNEL_NAME_ID_MAP = {
	TTD: '6',
};

export const HEADER = {
	SANDBOX_ID: 'X-H-SID',
	BACKEND_EXPERIMENT: 'SERVER-EXP',
	CLOUDFRONT_COUNTRY_CODE: 'cloudfront-viewer-country',
};

export const SESSION_TIME = {
	START_TIME: 'start_time',
	LAST_UPDATE_TIME: 'last_update_time',
};

export const SEO_META_DESC =
	'✔ Best Price Guaranteed ✔ Last-minute deals ✔ User reviews ✔ Hassle-free booking process ✔ 24x7 Customer Support';

export const HASOFFER_TRANSACTION_ID_KEY = 'transaction_id';

export const HASOFFER_OFFER_ID_KEY = 'offer_id';

export const HASOFFER_TRANSACTION_ID_COOKIE_KEY = 'hasoffer_transaction_id';

export const HASOFFER_OFFER_ID_COOKIE_KEY = 'hasoffer_offer_id';

export const COUNTRY_CODES = {
	US: {
		NAME: 'United States of America',
		ISO2: 'US',
		ISO3: 'USA',
	},
	FR: {
		NAME: 'France',
		ISO2: 'FR',
		ISO3: 'FRA',
	},
	IT: {
		NAME: 'Italy',
		ISO2: 'IT',
		ISO3: 'ITA',
	},
	AU: {
		NAME: 'Australia',
		ISO2: 'AU',
		ISO3: 'AUS',
	},
	SG: {
		NAME: 'Singapore',
		ISO2: 'SG',
		ISO3: 'SGP',
	},
	NL: {
		NAME: 'Nederlands',
		ISO2: 'NL',
		ISO3: 'NLD',
	},
	AT: {
		NAME: 'Austria',
		ISO2: 'AT',
		ISO3: 'AUT',
	},
	DE: {
		NAME: 'Germany',
		ISO2: 'DE',
		ISO3: 'DEU',
	},
};
export const CITIES = {
	NEW_YORK: 'NEW_YORK',
	LAS_VEGAS: 'LAS_VEGAS',
	SAN_FRANCISCO: 'SAN_FRANCISCO',
	DUBAI: 'DUBAI',
	ABU_DHABI: 'ABU_DHABI',
	SINGAPORE: 'SINGAPORE',
	BANGKOK: 'BANGKOK',
	LONDON: 'LONDON',
	EDINBURGH: 'EDINBURGH',
	SYDNEY: 'SYDNEY',
	MELBOURNE: 'MELBOURNE',
	HONG_KONG: 'HONG_KONG',
	PARIS: 'PARIS',
	ROME: 'ROME',
	AMSTERDAM: 'AMSTERDAM',
	BARCELONA: 'BARCELONA',
	MADRID: 'MADRID',
	VENICE: 'VENICE',
	NAPLES: 'NAPLES',
	MILAN: 'MILAN',
	BUDAPEST: 'BUDAPEST',
	FLORENCE: 'FLORENCE',
	SEVILLE: 'SEVILLE',
	BERLIN: 'BERLIN',
	GRANADA: 'GRANADA',
	PATTAYA: 'PATTAYA',
	SEOUL: 'SEOUL',
	OSAKA: 'OSAKA',
	TOKYO: 'TOKYO',
	TENERIFE: 'TENERIFE',
	HAMBURG: 'HAMBURG',
	PRAGUE: 'PRAGUE',
	REYKJAVIK: 'REYKJAVIK',
	MACHU_PICCHU: 'MACHU_PICCHU',
	PHUKET: 'PHUKET',
	CHIANG_MAI: 'CHIANG_MAI',
	MUNICH: 'MUNICH',
	KRAKOW: 'KRAKOW',
	CORDOBA: 'CORDOBA',
	BILBAO: 'BILBAO',
	BALI: 'BALI',
	SAN_DIEGO: 'SAN_DIEGO',
	PHILADELPHIA: 'PHILADELPHIA',
	WASHINGTON_DC: 'WASHINGTON_DC',
	LOS_ANGELES: 'LOS_ANGELES',
	CHICAGO: 'CHICAGO',
	BOSTON: 'BOSTON',
	ORLANDO: 'ORLANDO',
	SAN_ANTONIO: 'SAN_ANTONIO',
	MALAGA: 'MALAGA',
	LISBON: 'LISBON',
	VALENCIA: 'VALENCIA',
	VIENNA: 'VIENNA',
	DUBLIN: 'DUBLIN',
	ATHENS: 'ATHENS',
	ISTANBUL: 'ISTANBUL',
	ZURICH: 'ZURICH',
	GOLD_COAST: 'GOLD_COAST',
	CAIRNS: 'CAIRNS',
};

export const FIELD_NAME = {
	NAME: 'NAME',
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
	WEIGHT: 'WEIGHT',
	CUSTOM: 'CUSTOM',
	ADDRESS: 'ADDRESS',
};

export const COMMON_FIELD_NAME = {
	NAME: 'NAME',
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
};

export const PAYMENT_FIELD_NAME = {
	CARD_HOLDER_NAME: 'cardHolderName',
	CARD_NUMBER: 'cardNumber',
	MONTH: 'month',
	YEAR: 'year',
	CVV: 'cvv',
};

export const FIELD_ERROR = {
	NAME: strings.CPUD_ERROR_NAME,
	EMAIL: strings.CPUD_ERROR_EMAIL,
	PHONE: strings.CPUD_ERROR_PHONE,
	WEIGHT: strings.CPUD_ERROR_WEIGHT,
};

export const FIELD_DESCRIPTION = {
	EMAIL: strings.CPUD_YOUR_TICKETS_SENT_HERE,
	PHONE: strings.CPUD_MENTION_COUNTRY,
};

export const FIELD_LEVEL = {
	PRIMARY_CUSTOMER: 'PRIMARY_CUSTOMER',
	ALL_CUSTOMER: 'ALL_CUSTOMER',
};

export const MORE_LINKS_LIST = [
	{
		displayName: strings.CMN_ABOUT_US,
		trackingName: 'about',
		link: '/about-us',
	},
	{
		displayName: 'Careers',
		trackingName: 'careers',
		link: '/careers',
	},
	{
		displayName: 'Our Blog',
		trackingName: 'blog',
		link: 'https://www.headout.com/blog',
	},
	{
		displayName: 'Privacy Policy',
		trackingName: 'privacy-policy',
		link: '/privacy-policy',
	},
	{
		displayName: 'Terms of Usage',
		trackingName: 'terms-of-use',
		link: '/terms-of-use',
	},
];

export enum BANNERS_PLATFORM {
	MOBILE = 'MOBILE_WEB',
	DESKTOP = 'DESKTOP',
}

export enum LANDING_PAGE_BANNER_TYPES {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
}

export const ALERT_TYPE = {
	FOOTER_EMAIL_ALERT: 'FOOTER_EMAIL_ALERT',
	INVENTORY_NOT_AVAILABLE_ALERT: 'INVENTORY_NOT_AVAILABLE_ALERT',
	PRICE_DROP_ALERT: 'PRICE_DROP_ALERT',
};

export const SORT_TYPE = {
	PRICE: 'PRICE',
	POPULARITY: 'POPULARITY',
	TRENDING: 'TRENDING',
	CHRONOLOGICAL: 'CHRONOLOGICAL',
	RECOMMENDED: 'RECOMMENDED',
	DISCOUNT: 'DISCOUNT',
};

export const CITY_FILTER_TYPE = {
	CITY_TOP_OFFERS: 'CITY_TOP_OFFERS',
	COMBOS: 'COMBOS',
	CUSTOM_WIDGETS: 'CUSTOM_WIDGETS',
};

export const SORT_ORDER = {
	ASC: 'ASC',
	DESC: 'DESC',
};

export const FILTER_KEYS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
];

export const SORT_FILTER_KEYS = ['sort-type', 'sort-order'];

export const FILTER_AND_SORT_KEYS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
	'sort-type',
	'sort-order',
];

export const CATEGORY_PAGE_DEFAULT_SORTING_PARAMS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
	'sort-type',
	'sort-order',
	'limit',
	'offset',
];

export const PERSONA_PAGE_FILTER_AND_PAGINATION_KEYS = [
	'offset',
	'limit',
	'categories',
	'subCategories',
];

export const CITY_CATEGORY_PAGE_FILTER_AND_PAGINATION_KEYS = [
	'offset',
	'limit',
	'subCategoryIds',
	'sort-type',
	'sort-order',
];

export const FILTER_SORT_AND_OFFSET_KEYS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
	'sort-type',
	'sort-order',
	'offset',
	'limit',
];

export const HSID_COOKIE_NAME = 'h-sid';

export const GMAPS_PARAM = {
	v: '3.exp',
	key: 'AIzaSyASJdYx_PaF1NYURepT0FuEgqJIgy0wt30',
};

export const SIFT_JS_KEY = 'e6f5f84411';

export const PAYMENT_GATEWAY_TYPE = {
	ADYEN: 'ADYEN',
	CHECKOUT: 'CHECKOUT',
} as const;
export type TPaymentGatewayType =
	(typeof PAYMENT_GATEWAY_TYPE)[keyof typeof PAYMENT_GATEWAY_TYPE];

export const PAYMENT_METHOD = {
	CARD: 'CARD',
	APPLE_PAY: 'APPLEPAY_CHECKOUT',
	GOOGLE_PAY: 'GOOGLEPAY_CHECKOUT',
	PAYPAL: 'PAYPAL_CHECKOUT',
	IDEAL: 'IDEAL_CHECKOUT',
	FPX_ONLINE_BANKING: 'FPX_ONLINE_BANKING',
	AFFIRM: 'AFFIRM',
} as const;

export type TPaymentMethod =
	(typeof PAYMENT_METHOD)[keyof typeof PAYMENT_METHOD];

export const EXPRESS_CHECKOUT_OPTIONS = [
	PAYMENT_METHOD.APPLE_PAY,
	PAYMENT_METHOD.GOOGLE_PAY,
] as const;
export type TExpressCheckoutOptions = (typeof EXPRESS_CHECKOUT_OPTIONS)[number];

export const SENTRY_TAGS = {
	ERROR_PAGE_VIEWED: 'ERROR_PAGE_VIEWED',
	GOOGLE_PAY_API_FAILED: 'GOOGLE_PAY_API_FAILED',
	FINGERPRINT_ERROR: 'FINGERPRINT_ERROR',
};

export const PAYMENT_METHOD_ICONS = {
	[PAYMENT_METHOD.CARD]: CardSvg,
	[PAYMENT_METHOD.APPLE_PAY]: ApplePaySvg,
	[PAYMENT_METHOD.GOOGLE_PAY]: GooglePaySvg,
	[PAYMENT_METHOD.PAYPAL]: PayPalSvg,
	[PAYMENT_METHOD.IDEAL]: IDealSvg,
	[PAYMENT_METHOD.FPX_ONLINE_BANKING]: FPXSvg,
	[PAYMENT_METHOD.AFFIRM]: AffirmSvg,
};
export type TPaymentMethodIcon =
	(typeof PAYMENT_METHOD_ICONS)[keyof typeof PAYMENT_METHOD_ICONS];

export const TOP_CURRENCIES = ['EUR', 'USD', 'AED', 'SGD', 'INR', 'GBP'];

export const SEO_INDEXABLE_LANGUAGE_CODES = [
	'en',
	'fr',
	'de',
	'it',
	'es',
	'pt',
	'nl',
];

export const SEO_NON_INDEXABLE_LANGUAGE_CODES = [
	'ja',
	'ko',
	'zh-hans',
	'zh-hant',
	'ar',
	'pl',
	'id',
	'ru',
	'da',
	'no',
	'ro',
	'tr',
	'sv',
];

export const ACTIVE_LANGUAGE_CODES = [
	...SEO_INDEXABLE_LANGUAGE_CODES,
	...SEO_NON_INDEXABLE_LANGUAGE_CODES,
];

export const LANGUAGE_SORT_ORDER = ['en', 'es', 'fr', 'it', 'de', 'pt', 'nl'];

export const CALENDAR = {
	GOOGLE: 'GOOGLE',
	OUTLOOK: 'OUTLOOK',
	ICAL: 'ICAL',
};

export const TIMER_INFO_EXPERIMENT = 'TIMER_INFO_EXPERIMENT';

export const EXIT_INTENT_EXPERIMENT_KEY = 'exit_intent_shown_v1';

export const REFERRAL_LOGIN = 'REFERRAL_LOGIN';

export const INVENTORY_STRUCTURE = {
	SD_St_ST: 'SD_St_ST',
	SD_St_MT: 'SD_St_MT',
	SD_Mt_ST: 'SD_Mt_ST',
	SD_Mt_MT: 'SD_Mt_MT',
	MD_St_ST: 'MD_St_ST',
	MD_St_MT: 'MD_St_MT',
	MD_Mt_ST: 'MD_Mt_ST',
	MD_Mt_MT: 'MD_Mt_MT',
	UNAVAILABLE: 'Unavailable',
};

export const FLEXIBLE_START_TIME = 'FLEXIBLE_START_TIME';

export const THUNK_ERROR_TYPES = {
	CHECKOUT_PAGE: 'checkout',
	TOURS_PAGE: 'tourSelection',
};

export const THUNK_ERROR_CODES = {
	INVALID_CARD_DETAILS: 'CHECKOUT_TOKEN_CREATION',
	INVENTORY_EXPIRED: 'INVENTORY_EXPIRED',
};

export const SURVEY = {
	CHECKOUT_EXIT: 'checkoutPageHighIntent',
};

export const READ_MORE_BOX_MAX_HEIGHT = '4000px';

export const SALES_TRACKER_ID = 'xKAt5wZBBFuxqR7H2oMHEdeZksNqY2VL';

export const POWERED_BY_HEADOUT =
	'https://cdn-imgix-open.headout.com/logo/powered-by-headout-desktop-33467732.png?w=240&h=40&fit=fill';

export const UserProfileTypes = {
	CUSTOMER: 'CUSTOMER',
	DISTRIBUTION_PARTNER: 'DISTRIBUTION_PARTNER',
	ANONYMOUS: 'ANONYMOUS',
};

export const CITIES_EXCLUDED_FROM_VIEWING_TICKETS = [
	CITIES.DUBAI,
	CITIES.SINGAPORE,
];

export const PREFIX_TOGGLE_CLASS = 'prefix-hidden';

export const MWEB_DRAWER_TYPES = {
	PAX_DRAWER: 'Pax Drawer',
	TIME_DRAWER: 'Time Drawer',
	SEATS_DRAWER: 'Selected Seats Drawer',
	VARIANTS_DRAWER: 'Variant Selection',
};

export const SEARCH_DEBOUNCE_DELAY = 250;

export const LIVE_CHAT_DELAY = 7000;

export const GTM_INITIALIZATION_DELAY = 0;

export const TOUR_FLOW_TYPE = {
	MULTI_TOUR_FLOW: 'multi tour flow',
	SINGLE_TOUR_FLOW: 'single tour flow',
};

export const ANALYTICS_PLATFORM = {
	DESKTOP: 'Desktop',
	MOBILE: 'Mobile',
};

export const BANNER_POSITION = {
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
	NONE: 'NONE',
};

export const BANNER_PLATFORM = {
	ALL: 'ALL',
	MOBILE: 'MOBILE',
	DESKTOP: 'DESKTOP',
};

export const BANNER_TYPES = {
	COUPON: 'COUPON',
	COUPON_WITH_ACTION: 'COUPON_WITH_ACTION',
	APPLY_COUPON: 'APPLY_COUPON',
	ACTION: 'ACTION',
	FORM: 'FORM',
	INFORMATION: 'INFORMATION',
};

export const BANNER_DISPLAY_SIZE = {
	PARTIAL: 'PARTIAL',
	FULL: 'FULL',
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
};

export const LOGIN_OPTION = {
	FACEBOOK: 'facebook',
	GOOGLE: 'google',
	APPLE: 'apple',
	EMAIL: 'email',
};

export const LOGIN_RECIPES = {
	GOOGLE_ONE_TAP_SIGNIN: 'GOOGLE_ONE_TAP_SIGNIN',
};

export const GOOGLE_ONE_TAP = {
	MOMENT_TYPE: {
		DISPLAY: 'display',
		SKIPPED: 'skipped',
		DISMISSED: 'dismissed',
	},
	UI_STATUS: {
		DISPLAYED: 'Displayed',
		NOT_DISPLAYED: 'Not Displayed',
		SKIPPED: 'Skipped',
		DISMISSED: 'Dismissed',
	},
	SCRIPT_URL: 'https://accounts.google.com/gsi/client',
	SCRIPT_ID: 'google-one-tap-script',
};

export const LOGIN_EVENT = {
	ATTEMPTED: 'Sign In Attempted',
	SUCCESSFUL: 'Sign In Successful',
	FAILED: 'Sign In Failed',
};

export const ORDER_STATUS_API_POLLING_INTERVAL = 5000;

export const LIVE_CHAT_LICENCE_KEY = '8339531';

export const PREFERRED_COUNTRIES_CODES = [
	'us',
	'gb',
	'it',
	'fr',
	'ae',
	'sg',
	'au',
	'de',
	'th',
];

export const FLAGS_IMAGE =
	'https://cdn-imgix-open.headout.com/flags/flags@2x.png';

export const CARD_BIN_LENGTH = 6;

export const USER_STATUS_WHILE_BOOKING = {
	LOGGED_IN: 'LOGGED_IN',
	NOT_LOGGED_IN: 'NOT_LOGGED_IN',
};

export const MAX_LENGTH_FOR_PHONE_NUMBER = 18;

export const HEADOUT_SUPPORT_LINE = '+1 347-897-0100';

export const PERSONA = {
	TOURIST: 'tourist',
	LOCAL: 'local',
};

export const SEARCH_LOCATION = {
	TOP: 'top',
	MID: 'mid',
	TAB: 'tab',
	NONE: 'none',
	LEFT: 'left',
};

export const PROFILE_PAGE_MODAL = {
	NONE: 'NONE',
	CURRENCY: 'CURRENCY',
	LANGUAGE: 'LANGUAGE',
	MARKUP: 'PRICE_MARKUP',
	SEARCH: 'SEARCH',
};

export const NYC_CONCIERGE_SUPPORT_PHONE_NUMBER = '+1 347-474-6777';

export const HARRY_POTTER_CURSED_CHILD_TGID = '16816';

export const BROADWAY_MAX_PAX = 6;

export const BROADWAY_TIMER_DEFAULT = 480000;

export const BROADWAY_ACCORDION = {
	DATE: 'Date',
	TIME: 'Time',
	PAX: 'Pax',
	TOUR: 'Tour',
};

export const RESERVATION_SESSION_STORAGE_KEY = 'reservationSessions';

export const UNIT_ABBREVIATIONS = ['K', 'M', 'B', 'T'];

export const APP_DEEP_LINKS = {
	ANDROID: 'android-app://com.tourlandish.chronos/https/applink.headout.com/',
	IOS: 'ios-app://899327000/https/applink.headout.com/',
};

export const TOP_PERFORMING_CITIES = [
	'NEW_YORK',
	'LAS_VEGAS',
	'ROME',
	'PARIS',
	'LONDON',
	'DUBAI',
	'BARCELONA',
];

export const HEADOUT_DOMAIN = 'www.headout.com';

export const getDescriptorsMap = () => ({
	FREE_CANCELLATION: {
		icon: 'cancel',
		label: strings.PPD_FREE_CANCEL,
	},
	HOTEL_PICKUP: { icon: 'taxi', label: strings.PPD_HOTEL_PICKUP },
	INSTANT_CONFIRMATION: {
		icon: 'instant',
		label: strings.PPD_INSTANT_CONFIRMATION,
	},
	MOBILE_TICKET: { icon: 'mobile', label: strings.PPD_MOBILE_TICKET },
	SKIP_THE_LINE: { icon: 'skip', label: strings.PPD_SKIP_LINE },
	AUDIO_GUIDE: {
		icon: 'headphones',
		label: strings.PPD_AUDIO_GUIDE,
	},
	GUIDED_TOUR: { icon: 'guide', label: strings.PPD_GUIDED_TOUR },
	EXTENDED_VALIDITY: {
		icon: 'validity',
		label: strings.PPD_VALIDITY,
	},
	TRANSFERS: {
		icon: 'transfer',
		label: strings.PPD_RETURN_TRANSFERS,
	},
	MEALS_INCLUDED: {
		icon: 'food',
		label: strings.PPD_MEALS_INCLUDED,
	},
});

export const DESCRIPTORS = {
	INSTANT_CONFIRMATION: 'INSTANT_CONFIRMATION',
	FREE_CANCELLATION: 'FREE_CANCELLATION',
};

export const PROFILE_PAGE_TAB = {
	BOOKING: 'booking',
	CREDITS: 'credits',
	ABOUT_HEADOUT: 'about',
	PRICE_MARKUP: 'markup',
	SIGN_OUT: 'sign-out',
	RESET_PASSWORD: 'reset',
};

export const SEATMAP_RESIZE_THRESHOLD = 100;

export const ATTRACTION_TRACKER_AIRTABLE_LINK = {
	DESKTOP:
		'https://airtable.com/embed/shrxPunRNi4o7WOoD?backgroundColor=gray&viewControls=on',
	MOBILE: 'https://airtable.com/embed/shraqmmKupJPPesvY?backgroundColor=gray&viewControls=on',
};

export const COVID_PAGES_MORE_READS = [
	{
		heading: 'Post COVID 19 Packing Essentials',
		link: 'https://www.headout.com/blog/post-covid-travel-packing-checklist/',
		imageUrl:
			'https://cdn-imgix-open.headout.com/Post+Covid+19+Travel/Packing+Essentials.jpg',
		description: 'The ultimate post COVID-19 packing checklist',
	},
	{
		heading: 'Travel Reopening Trends - Future of Travel',
		link: 'https://www.headout.com/blog/post-covid-travel-reopening-trends',
		imageUrl:
			'https://cdn-imgix-open.headout.com/Post+Covid+19+Travel/Global+Travel+Reopening+Tracker+Social+Media.jpg',
		description: 'Travel trends and what lies ahead',
	},
	{
		heading: 'When Can We Travel Again',
		link: 'https://www.headout.com/blog/when-can-we-travel-again/',
		imageUrl:
			'https://cdn-imgix-open.headout.com/blog/Travel+Reopens/Travel+Bubble.jpg',
		description: 'Travel bubbles and the reopening of countries',
	},
	{
		heading: 'Headout Attraction and Country Tracker Trends',
		link: 'https://www.headout.com/blog/emerging-travel-trends-post-covid/',
		imageUrl:
			'https://cdn-imgix-open.headout.com/Post+Covid+19+Travel/Travel+Trends.jpg',
		description: 'Attraction opening dates, new rules and more',
	},
];

export const SLIDERS_X_AXIS_SCROLL_MIN_THRESHOLD = 5;
export const SLIDERS_Y_AXIS_SCROLL_MIN_THRESHOLD = 50;

export const AIRTABLE_BASE_LINK =
	'https://api.airtable.com/v0/appCiV6q7KyN8meXg';

export const QUERY_PARAM = {
	CURRENCY_CODE: 'currencyCode',
	LANGUAGE_CODE: 'languageCode',
	DATE: 'date',
	TIME: 'time',
	TOUR_ID: 'tourId',
	VARIANT_ID: 'variantId',
	PAX_PREFIX: 'pax.',
	SOURCE: 'source',
	REDIRECT_RESULT: 'redirectResult',
	RESULT_CODE: 'resultCode',
	COUPON_CODE: 'couponCode',
	TRACK_CONVERSION: 'trackConversion',
	SELECTOR: 'selector',
	RETAIN: 'retain',
	DF_SELECTION: 'dfSelection',
	AFFILIATE_CODE: 'affiliate_code',
	INSTANT_CHECKOUT: 'instantCheckout',
	GA_TRACKER: '_gl',
	SUB_ID: 'sub_id',
	OPEN_FILTER_TRAY: 'filterSelectionTray',
	OPEN_CATEGORIES_TRAY: 'categorySelectionTray',
	EDIT_VARIANT_ID: 'editVariantId',
	CREDIT_CASHBACK: 'creditCashback',
	LIMIT: 'limit',
	OFFSET: 'offset',
	REDIRECT: 'redirect',
	SECURE_ID: 'secureId',
	CKO_SESSION_ID: 'cko-session-id',
	IMPACT_CLICK_ID: 'irclickid',
	LIVE_PRICE_DISMISS: 'lp-dismiss',
	BY_HO: 'byHO',
	AIRPORT_TRANSFER_QUOTE_ID: 'airportTransferQuoteId',
	HOHO_MWEB_FULL_SCREEN_PAX: 'showFullScreenPax',
	ATRIBUTION_CHANNEL_ID: 'ci',
	ATRIBUTION_CHANNEL_META: 'cm',
	PARTNER_TRACE_ID: 'partner_trace_id',
	UTM_SOURCE: 'utm_source',
	UTM_CONTENT: 'utm_content',
	UTM_TERM: 'utm_term',
	EXPERIMENT_OVERRIDE: 'experimentOverride',
	UTM_CAMPAIGN: 'utm_campaign',
	LOCALIZED_ERROR_MESSAGE: 'localized-error-message',
	GOOGLE_CAMPAIGN_ID: 'gclid',
	FACEBOOK_CAMPAIGN_ID: 'fbclid',
	BING_CAMPAIGN_ID: 'msclkid',
};

export const LSCACHE_PARAM = {
	DATE: 'date',
	TIME: 'time',
};

export const UNKNOWN_ERROR = 'Unknown Error';

export const CANONICAL_QUERY_WHITELIST = [
	QUERY_PARAM.LIMIT,
	QUERY_PARAM.OFFSET,
];

export const QUERY_PARAM_WHITELIST = [
	QUERY_PARAM.GA_TRACKER,
	QUERY_PARAM.HOHO_MWEB_FULL_SCREEN_PAX,
];

export const HEADOUT_FROM_HOME = {
	IMAGE: 'https://cdn-imgix-open.headout.com/headout-from-home/popup.jpg',
	LINK: 'https://www.headout.com/blog/virtual-tour-world/',
	LOCAL_STORE_KEY: 'headout_from_home_popup_closed',
	WAIT_TIME: 25000,
};

export const DOWNLOAD_APP_SECTION_ASSETS_BY_LANG = {
	en: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/en.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/en.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=en',
		APP_STORE_LINK:
			'https://apps.apple.com/en/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	es: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/Spanish.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/es.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/es.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=es',
		APP_STORE_LINK:
			'https://apps.apple.com/es/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	fr: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/French.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/fr.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/fr.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=fr',
		APP_STORE_LINK:
			'https://apps.apple.com/fr/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	it: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/Italian.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/it.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/it.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=it',
		APP_STORE_LINK:
			'https://apps.apple.com/it/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	de: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/German.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/de.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/de.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=de',
		APP_STORE_LINK:
			'https://apps.apple.com/de/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	pt: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/Portugal.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/pt.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/pt.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=pt',
		APP_STORE_LINK:
			'https://apps.apple.com/pt/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	nl: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/Dutch.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/nl.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/nl.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=nl',
		APP_STORE_LINK:
			'https://apps.apple.com/nl/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	da: {
		// Using English as fallback for Danish, since it's currently not available.
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/da.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/da.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=da',
		APP_STORE_LINK:
			'https://apps.apple.com/da/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	no: {
		// Using English as fallback for Norwegian, since it's currently not available.
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/no.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/no.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=no',
		APP_STORE_LINK:
			'https://apps.apple.com/no/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	pl: {
		// Using English as fallback for Polish, since it's currently not available.
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/pl.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/pl.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=pl',
		APP_STORE_LINK:
			'https://apps.apple.com/pl/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	ro: {
		// Using English as fallback for Romanian, since it's currently not available.
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/ro.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/ro.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=ro',
		APP_STORE_LINK:
			'https://apps.apple.com/ro/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	ru: {
		// Using English as fallback for Russian, since it's currently not available.
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/ru.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/ru.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=ru',
		APP_STORE_LINK:
			'https://apps.apple.com/ru/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	sv: {
		// Using English as fallback for Swedish, since it's currently not available.
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/sv.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/sv.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=sv',
		APP_STORE_LINK:
			'https://apps.apple.com/sv/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
	tr: {
		IPHONE_MOCKUP_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/app/English.png',
		PLAY_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/play-store/tr.png',
		APP_STORE_IMAGE_URL:
			'https://cdn-imgix.headout.com/assets/images/badges/app-store/tr.png',
		PLAY_STORE_LINK:
			'https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=tr',
		APP_STORE_LINK:
			'https://apps.apple.com/tr/app/headout-travel-experiences/id899327000?ls=1&mt=8',
	},
};

export const CONTACT_ICONS = {
	EMAIL: 'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Mail.svg',
	PHONE: 'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Phone.svg',
	HELP: 'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Help.svg',
	MESSAGE:
		'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Message.svg',
};

export const PAYMENT_CARD_ICONS = {
	VISA: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/Visa.svg',
	MASTERCARD:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/MasterCard.svg',
	AMEX: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/AmexCard.svg',
	PAYPAL: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/PayPalLight.svg',
	MAESTROCARD:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/MaestroCard.svg',
	APPLEPAY:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/ApplePay.svg',
	GPAY: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/GPay.svg',
	DISCOVER:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/DiscoverCard.svg',
	DINERS: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/DinerClub.svg',
	IDEAL: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/IDealCard.svg',
};

export const SOCIAL_LINKS = {
	FB_URL: 'https://www.facebook.com/headoutapp',
	TWITTER_URL: 'https://www.twitter.com/headout',
	INSTAGRAM_HEADOUT_URL: 'https://www.instagram.com/headout/',
	INSTAGRAM_HEADOUT_DUBAI_URL: 'https://www.instagram.com/headoutuae/',
	PINTEREST_URL: 'https://in.pinterest.com/headout/',
	YOUTUBE_URL: 'https://www.youtube.com/c/Headout-Official',
	LINKEDIN_URL: 'https://www.linkedin.com/company/headout-com/mycompany/',
};

export const DOWNLOAD_APP_QR =
	'https://cdn-imgix-open.headout.com/headout-connect/app_download.svg';

export const NON_BOOKING_FLOW_REGEX = /^((?!book|confirmation).)*$/;

export const INFORMATION_BANNER_TYPE = {
	CANCELLATION_INFO: 'CANCELLATION_INFO',
};

export const BLOG_LINK = 'https://www.headout.com/blog/';

export const COMPANY_BLOG_LINK = 'https://backstage.headout.com/';

export const HEADOUT_STUDIO_LINK = 'https://www.headout.studio/';

export const INFLUENCER_PROGRAM_LINK =
	'https://form.typeform.com/to/I9Seu67S?utm_source=headout&utm_medium=web&utm_campaign=hcp_influencer';

export const HUB_LINK = 'https://hub.headout.com/login/';

export const JOIN_HEADOUT_LINK = 'https://hub.headout.com/';

export const AFFILIATES_PORTAL_LINK = 'https://partner.headout.com/affiliate/';

export const ANALYTICS_FLOW_TYPE = {
	SINGLE_TOUR: 'single-tour',
	MULTI_TOUR: 'multi-tour',
	HIFI_SEATMAP: 'hifi-seatmap',
	LOFI_SEATMAP: 'lofi-seatmap',
	COMBO: 'combo',
	RESERVE: 'reserve',
	OPEN_DATED_NO_VARIANT: 'Open Dated No Variant',
	OPEN_DATED_MULTI_VARIANT: 'Open Dated Multi Variant',
	PRIVATE_AIRPORT_TRANSFERS: 'Private Airport Transfers',
	SHARED_AIRPORT_TRANSFERS: 'Shared Airport Transfers',
	HOP_ON_HOP_OFF: 'Hop-On Hop-Off',
	PROPERTY_SELECTION: 'PROPERTY_SELECTION',
	GUIDED_TOUR_PROPERTY_SELECTION: 'GUIDED_TOUR_PROPERTY_SELECTION',
};

export const CREDITS_TERMS_AND_CONDITIONS_LINK = '/credits-policy';

export const PLATFORM = {
	QUERY: 'platform-source',
	APPS: 'apps',
	WEB: 'web',
};

export const PRISMIC_CUSTOM_TYPES = {
	HO_CATEGORY_CONTENT: 'ho_category_content',
};

export const PRISMIC_LOCALE_MAP = {
	en: 'en-us',
	es: 'es-es',
	it: 'it-it',
	fr: 'fr-fr',
	pt: 'pt-pt',
	nl: 'nl-nl',
	de: 'de-de',
	ja: 'ja-jp',
	'zh-hans': 'zh-cn',
	'zh-hant': 'zh-tw',
	ar: 'ar-ae',
};

export const SORT_ORDER_KEY = {
	ASC: 'ASC',
	DESC: 'DESC',
};

export const SORT_TYPE_KEY = {
	RECOMMENDED: 'RECOMMENDED',
	POPULARITY: 'POPULARITY',
	PRICE: 'PRICE',
};

export const SORT_TYPE_MODES = {
	RECOMMENDED: {
		key: SORT_TYPE_KEY.RECOMMENDED,
		urlParamValue: null,
		defaultOrder: SORT_ORDER_KEY.ASC,
	},
	POPULARITY: {
		key: SORT_TYPE_KEY.POPULARITY,
		urlParamValue: 'POPULARITY',
		defaultOrder: SORT_ORDER_KEY.DESC,
	},
	PRICE: {
		key: SORT_TYPE_KEY.PRICE,
		urlParamValue: 'PRICE',
		defaultOrder: SORT_ORDER_KEY.ASC,
	},
};

export const SORT_MODE_KEY = {
	RECOMMENDED: 'RECOMMENDED',
	POPULARITY: 'POPULARITY',
	PRICE_LOW_TO_HIGH: 'PRICE_LOW_TO_HIGH',
	PRICE_HIGH_TO_LOW: 'PRICE_HIGH_TO_LOW',
};

export const SORT_MODE = {
	RECOMMENDED: {
		key: SORT_MODE_KEY.RECOMMENDED,
		label: 'CP_PICKED_FOR_YOU', // cannot localize here since this gets pre-computed
		type: SORT_TYPE_MODES.RECOMMENDED,
		order: null, // RECOMMENDED does not require a sort order. The default though is ASC.
	},
	POPULARITY: {
		key: SORT_MODE_KEY.POPULARITY,
		label: 'CP_SORT_POPULARITY',
		type: SORT_TYPE_MODES.POPULARITY,
		order: SORT_ORDER_KEY.DESC,
	},
	PRICE_LOW_TO_HIGH: {
		key: SORT_MODE_KEY.PRICE_LOW_TO_HIGH,
		label: 'CP_PRICE_LOW_HIGH',
		type: SORT_TYPE_MODES.PRICE,
		order: SORT_ORDER_KEY.ASC,
	},
	PRICE_HIGH_TO_LOW: {
		key: SORT_MODE_KEY.PRICE_HIGH_TO_LOW,
		label: 'CP_PRICE_HIGH_LOW',
		type: SORT_TYPE_MODES.PRICE,
		order: SORT_ORDER_KEY.DESC,
	},
};

export const BARCELONA_PASS_URL = 'https://www.barcelonapass.com/';

export const PRODUCT_CARD_TYPES = {
	SEARCH_CARD: 'search_card',
	REGULAR: 'regular',
};

export const RECENTLY_VIEWED_ENTITIES = 'recentlyViewedEntities';

export const INSTANT_CHECKOUT_TOUR_IDS = 'instantCheckoutTourIds';

export const RECENTLY_VIEWED_CITIES_KEY = 'recentlyViewedCities';

export const CARD_NUMBER_PLACEHOLDER = '0000 0000 0000 0000';

export const SECURITY_COMPLIANCE_SVG =
	'https://cdn-s3.headout.com/assets/images/checkout/security-compliance-v1.svg';

export const SEARCHABLE_MODAL_MIN_ITEMS = 10;

export const REWRITE_NORMALISED_HREF_MAP = {
	EXPERIENCE_PAGE: '/:lang/:slug/(.*)e-:id(\\d+)/',
	CATEGORY_PAGE: '/:lang/(.*)c-:id(\\d+)/',
	CITY_PAGE: '/:lang/(.*)city-:city/',
};

export const NEXT_LOCATION_PAGE_PATH = {
	HOME_PAGE: '/',
	CITY_PAGE: '/[lang]/cities/[city]',
	CATEGORY_PAGE: '/[lang]/category/[id]',
	EXPERIENCE_PAGE: '/[lang]/tour/[id]',
	COLLECTION_PAGE: '/[lang]/collection/[id]',
	SUB_CATEGORY_PAGE: '/[lang]/subcategory/[id]',
	BOOK_PAGE: '/[lang]/book/[id]/[...slug]',
	CONFIRMATION_PAGE: '/[lang]/confirmation/[piid]',
};

export const COLLECTION_PAGE_SECTIONS = {
	HEADOUT_PICKS: 'HEADOUT_PICKS',
	TTD_HIGHLIGHT: 'TTD_HIGHLIGHT',
	COLLECTION: 'GENERIC',
	CATEGORY: 'CATEGORY',
	SUB_CATEGORY: 'SUB_CATEGORY',
	PINNED_CARDS: 'PINNED_CARDS',
	CAROUSEL: 'CAROUSEL',
};

export const PERSONA_PAGE_SECTIONS = {
	PRODUCT_CARDS: 'PRODUCT_CARDS',
	CATEGORY_FILTER: 'CATEGORY_FILTER',
};

export const GLOBAL_CITY_CODE = 'GLOBAL';
export const ALL_COLLECTIONS = 'ALL_COLLECTIONS';
export const ALL_SUB_CATEGORIES = 'ALL_SUB_CATEGORIES';
export const BOOKING_API_FLOW_EVENTS = {
	FAILURE: 'Booking API Failure',
};

export const FAIL_ITINERARY_API = {
	USER_MESSAGE: 'Fail Itinerary API Error Message',
	API_FAILURE: 'Fail Itinerary API Failure',
};

export const VERIFICATION_PAYMENT_API_FAILURE =
	'Verification API Payment Failure';

export const IDEAL_3DS_FAIL_PAYMENT_ERROR_MESSAGE =
	'Ideal & 3ds fail payment error message';

export const FILTER_VIEW_PAGE_TYPE = {
	CATEGORY: 'Category Page',
	SUB_CATEGORY: 'Sub Category Page',
	TOUR_LIST_PAGE: 'Tour List Page',
	PERSONA: 'Persona Page',
};

export const CANCELLATION_POLICY = {
	FULL: 'FULL',
	PARTIAL: 'PARTIAL',
};

export const GLOBAL_HEADOUT_PICKS_TAG = 'GLOBAL-HEADOUT-PICKS';

export const NOTIFY_TOAST_TYPES = {
	SUCCESS: 'success',
	WARN: 'warning',
	ERROR: 'error',
};

export const SUFFIX_PRODUCT_NAME = 'SFX-';

export const MAX_COLLECTION_CARDS_IN_HEADER = 15;

export const PRODUCT_CARD_TYPE = {
	COMPARISON: 'Comparison',
	STANDARD: 'Standard',
	PINNED: 'Pinned',
	GOOGLE_TTD_PINNED: 'Google TTD Pinned',
	RECENTLY_VIEWED: 'Recently Viewed',
	TOP_OFFERS: 'Top Offers',
};

export const NEXT_NEW_PRODUCTS_PAGINATION = 12;
export const PRODUCTS_GRID_DEFAULT_COUNT = 8;
export const HEADOUT_PICKS_EXPERIENCES = 50;
export const TOP_DESTINATIONS_COUNT = 30;
export const TOP_ATTRACTIONS_COUNT = 10;
export const TOP_EXPERIENCES_COUNT = 10;
export const NEAR_BY_CITIES_COUNT = 10;
export const COLLECTION_PAGE_PRODUCT_CARD_COUNT = 9;

export const INVENTORY_STATUS = {
	CLOSED: 'CLOSED',
};

export const COMBO_SUBSTEP = {
	DATE_TIME: 'DATE_TIME',
	PAX_FIELDS: 'PAX_FIELDS',
};

export const RESERVATION_TYPE = {
	UPCOMING: 'UPCOMING',
	PREVIOUS: 'PREVIOUS',
};

export const DAY_MONTH_DATE_YEAR_FORMAT = 'ddd, MMM D, YYYY';

export const TRAILING_CURRENCY_SYMBOLS_LANGS = ['it', 'de', 'fr', 'es'];

export const LANGS_WITH_COMMA_IN_PRICE = ['de', 'es', 'fr', 'pt', 'it', 'nl'];

export const LANGS_WITH_SPECIAL_PERCENTAGE_FORMATTING = ['de', 'fr'];

export const DE_TIME_SUFFIX = 'Uhr';

export const DEFAULT_REVIEWER_DP =
	'https://cdn-s3-open.headout.com/reviews/default.png';

export const CALENDAR_CONSTS = {
	TOTAL_WEEK_DAYS: 7,
	FADED_DATE_PRICE_LEN: 8,
	MONTHS_FIRST_INDEX: 0,
	MONTHS_LAST_INDEX: 11,
	MAX_INVS_IN_DATE_LIST_CAL: 12,
	REGULAR_DUMMY_CAL_ROWS_COUNT: 5,
	DUMMY_CAL_MONTHS_COUNT_MWEB: 3,
	CAL_TYPES: {
		DATE_LIST_CALENDAR: 'Date List Calendar',
		SEVEN_DAY_CALENDAR: 'Seven Day Calendar',
		REGULAR_CALENDAR: 'Regular Calendar',
	},
	SEVEN_DAY_CAL: {
		MAX_MONTHS_IN_A_COL: 3,
		MAX_MONTHS_AT_A_TIME: 6,
	},
	HORIZONTAL_DATE_LIST: {
		SHOW_MAX_DATES_DESKTOP: 7,
		SHOW_MAX_DATES_MWEB: 5,
		PRICE_ROUND_UP_THRESHOLD: 6,
	},
};

export const APPLE_PAY = {
	VERSION: 4,
	MERCHANT_ID: 'merchant.com.tourlandish.checkout.www.eu',
	SUPPORT_TYPE: {
		ACTIVE: 'ACTIVE',
		PASSIVE: 'PASSIVE',
	},
	SUPPORTED_NETWORKS: ['visa', 'masterCard', 'amex', 'discover'],
	MERCHANT_CAPABILITIES: ['supports3DS'],
	BUTTON_TYPE: 'book',
};

export const GOOGLE_PAY = {
	API_VERSION: 2,
	API_VERSION_MINOR: 0,
	tokenizationSpecifications: {
		type: 'PAYMENT_GATEWAY',
		gateway: 'checkoutltd',
	},
	CARD_PAYMENT_METHOD: {
		type: 'CARD',
		parameters: {
			allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
			allowedCardNetworks: [
				'AMEX',
				'DISCOVER',
				'INTERAC',
				'JCB',
				'MASTERCARD',
				'MIR',
				'VISA',
			],
		},
	},
	SUPPORT_TYPE: {
		PASSIVE: 'PASSIVE',
		ACTIVE: 'ACTIVE',
		NOT_SUPPORTED: 'NOT_SUPPORTED',
	},
	GOOGLE_MERCHANT_ID: {
		TEST: '12345678901234567890',
	},
	ENVIRONMENT: {
		TEST: 'TEST',
		PRODUCTION: 'PRODUCTION',
	},
	HEADOUT_ACQUIRER_BANK_COUNTRY_CODE: 'DE',
	MERCHANT_NAME: 'Headout, Inc.',
	IOS_SUPPORT_TYPE_COOKIE: 'iosGpaySupportType',
};

export const TOUR_SELECTION_MODAL = {
	TIME: 'TIME',
	PAX: 'PAX',
};

export const THREE_DS_REDIRECT_TYPE = {
	FAILURE: '3DS_FAILURE',
	SUCCESS: '3DS_SUCCESS',
};

export const ALTS_CANCELLATION_SLACK_CHANNEL_ID = 'G2DLJQC14';

export const BOOKING_SELECTION_LSCACHE_EXPIRY = 30;

export const LOG_LEVELS = {
	INFO: 'INFO',
	VERBOSE: 'VERBOSE',
	WARNING: 'WARNING',
	ERROR: 'ERROR',
	CRITICAL: 'CRITICAL',
};

export const LOG_CATEGORIES = {
	API_ERROR: 'API_ERROR',
	CLIENT_EXCEPTION: 'CLIENT_EXCEPTION',
	PAGE_LOADING_ERROR: 'PAGE_LOADING_ERROR',
	EMAIL_CHECK_FAILED: 'EMAIL_CHECK_FAILED',
	TGID_NOT_FOUND: 'TGID_NOT_FOUND',
};

export const DESCRIPTORS_RANKING_LOGIC = [
	'validity',
	'cancel',
	'instant',
	'mobile',
	'clock',
	'headphones',
	'guide',
	'transfer',
	'taxi',
	'food',
];

export const MODAL_CLOSE_REASON = {
	CLICK_OUTSIDE: 'CLICK_OUTSIDE',
	CLOSE_BUTTON: 'CLOSE_BUTTON',
	AUTOMATIC: 'AUTOMATIC',
};

export const MAX_DESCRIPTORS_DISPLAYED = 6;

export enum seatMapListenerType {
	iframeInitCompleted = 'iframeInitCompleted',
	initializingSeatmapStarted = 'initializingSeatmapStarted',
	initializingSeatmapCompleted = 'initializingSeatmapCompleted',
	onSeatSelectionChanged = 'onSeatSelectionChanged',
	onSeatSelectionSubmitted = 'onSeatSelectionSubmitted',
	onSeatAdded = 'onSeatAdded',
	onSeatRemoved = 'onSeatRemoved',
	inventoryUpdateStarted = 'inventoryUpdateStarted',
	inventoryUpdateCompleted = 'inventoryUpdateCompleted',
	onZoomLevelChanged = 'onZoomLevelChanged',
	onZoomInDoubleClick = 'onZoomInDoubleClick',
	onZoomInButtonClick = 'onZoomInButtonClick',
	onZoomOutButtonClick = 'onZoomOutButtonClick',
	onZoomResetButtonClick = 'onZoomResetButtonClick',
	onPriceFilterClick = 'onPriceFilterClick',
	onSeatsViewExpanded = 'onSeatsViewExpanded',
	setInventorySlot = 'setInventorySlot',
	selectSeats = 'selectSeats',
	removeSeat = 'removeSeat',
	addSeat = 'addSeat',
	initPlugin = 'initPlugin',
	init = 'init',
	priceListClicked = 'priceListClicked',
	priceListOpened = 'priceListOpened',
	priceListClosed = 'priceListClosed',
	clearFilterClicked = 'clearFilterClicked',
	applyFilterClicked = 'applyFilterClicked',
	log = 'log',
}

export const LOCALE_MAP = {
	en: 'en_US',
	es: 'es_ES',
	fr: 'fr_FR',
	de: 'de_DE',
	it: 'it_IT',
	nl: 'nl_NL',
	pt: 'pt_PT',
};

export const APPLE_SIGN_IN = {
	CLIENT_ID: 'com.headout.websignin',
};

export const TICKETS_CATEGORY = 'Tickets';

export const CLARITY_PROJECT_ID_1 = 'bkr8q7wx0t';

export const CLARITY_PROJECT_ID_2 = 'bkra4tjmu8';

export const CLARITY_PLAYER_URL = 'https://clarity.microsoft.com/player/';

export const MUST_DO_THINGS_CARDS_COUNT = { MIN: 4, MAX: 8 };

export const SECTION_CONSTRUCTOR_PREFIX = 'CAROUSEL';
export const LAZY_COMPONENT_SECTION_WRAPPER_PREFIX = 'LAZY_CAROUSEL';

export const VALIDITY_TYPES = {
	UNTIL_DATE: 'UNTIL_DATE',
	UNTIL_DAYS_FROM_PURCHASE: 'UNTIL_DAYS_FROM_PURCHASE',
	EXTENDABLE_BUT_UNKNOWN: 'EXTENDABLE_BUT_UNKNOWN',
	NOT_EXTENDABLE: 'NOT_EXTENDABLE',
};

export const BLOG_POSTS_TYPE = {
	GENERAL: 'GENERAL',
	LTD_BROADWAY_GUIDE: 'GUIDE',
	CITY_GUIDE: 'TRAVEL_GUIDE',
	LTD_AND_BROADWAY: ['SHOWS', 'CHEAP_TICKET', 'SEATING', 'RESTAURANTS'],
	CITY: ['ITINERARIES', 'BEST_TIME_TO_VISIT', 'DAY_TRIPS', 'TIPS'],
};

export const BLOG_PAGE_TYPE = {
	NORMAL: 'NORMAL',
	CITY: 'CITY',
	BROADWAY: 'BROADWAY',
	LTD: 'LTD',
};

export const BLOGS_SUPPORTED_LANG = ['en'];

export const BLOG_FALLBACK_IMAGE =
	'https://cdn-imgix-open.headout.com/blog/media/images/Blog.png';

export const BLOG_CARD_IMAGE_HEIGHT = {
	desk: {
		default: 284,
		small: 112,
	},
	mweb: {
		default: 88,
	},
};

export const BLOG_CARD_IMAGE_WIDTH = {
	desk: {
		default: 282,
		small: 180,
	},
	mweb: {
		default: 141,
	},
};

export const BLOG_GUIDE_CARD_IMAGE_HEIGHT = {
	desk: {
		default: 379,
	},
	mweb: {
		default: 152,
	},
};

export const BLOG_GUIDE_CARD_IMAGE_WIDTH = {
	desk: {
		default: 262,
	},
	mweb: {
		default: 290,
	},
};
export const BLOG_GUIDE_CARD_MAP_IMAGE_HEIGHT = 146;
export const BLOG_GUIDE_CARD_MAP_IMAGE_WIDTH = 155;

export const LTD_COLLECTION_ID = 167;

export const BROADWAY_COLLECTION_ID = 24;

export const RTL_LANGUAGE_CODES = ['ar'];

export const REM_VALUES = {
	DESKTOP: {
		1440: 16,
		1280: 14.4,
		1024: 12.8,
	},
	MOBILE: 16,
};

export const ENTITY_ICONS_FOLDER_LINK =
	'https://cdn-imgix-open.headout.com/categories';

export const PERSONA_ASSETS_FOLDER_LINK =
	'https://cdn-imgix.headout.com/persona-affinity';

export const LFC_SLICE_TYPES = {
	RICH_TEXT: 'rich_text',
	RICH_TEXT_ONLY: 'rich_text_only',
	TAB_WRAPPER: 'tab_wrapper',
	TAB: 'tab',
	ACCORDION: 'accordion',
	ACCORDION_ROW: 'accordion_row',
	FAQ_ROW: 'faq_row',
};

export const ZENDESK_VALIDITY_REGEX = /^[a-zA-Z0-9~!$%^&*_=+}{'\?\-\.@]+$/;

export const HSID_DEFAULT_VALUE = 'HSID_DEFAULT_VALUE';

export const DOMAINS_WITH_INDEXED_BOOKING_FLOWS = [
	'book.london-theater-tickets.com',
];

export const SEARCH_RESULT_TYPE = {
	CITY_COLLECTION: 'City Collection',
	CITY_EXPERIENCE: 'City Experience',
	WORLDWIDE_EXPERIENCE: 'Worldwide Experience',
	WORLDWIDE_COLLECTION: 'Worldwide Collection',
};

export const DINO_LOTTIE_URL =
	'https://cdn-imgix-open.headout.com/search/dino.json';

export const CASHBACK_LOTTIE_URL = `https://cdn-imgix.headout.com/assets/lottie/cashbackCoinAnimation.json`;

export const HEARTS_LOTTIE_URL =
	'https://cdn-imgix.headout.com/assets/lottie/reviewCountryBannerBackgroundHearts.json';

export const FIREWORKS_LOTTIE_URL =
	'https://cdn-imgix.headout.com/assets/lottie/fireworksAnimation.json';

export const DISNEY_PROMO_LOTTIE = {
	MWEB: 'https://cdn-imgix.headout.com/assets/lottie/disney-mweb-3.json',
	DWEB: 'https://cdn-imgix.headout.com/assets/lottie/disney-dweb-2.json',
};

export const STUDENTBEANS_CONNECT_LINK = {
	en: 'https://connect.studentbeans.com/v4/headout/us',
	fr: 'https://connect.studentbeans.com/v4/headout/fr',
	de: 'https://connect.studentbeans.com/v4/headout/de',
	es: 'https://connect.studentbeans.com/v4/headout/es',
	it: 'https://connect.studentbeans.com/v4/headout/it',
	// We will use en for PT and NL because localisation is tied to the territories we sign up for.
	pt: 'https://connect.studentbeans.com/v4/headout/us',
	nl: 'https://connect.studentbeans.com/v4/headout/us',
};

export const PARTNERS_IMAGES = {
	FOLDER_LINK: 'https://cdn-imgix-open.headout.com/partner-images',
	LIST: [
		{
			id: 'disneyland-paris',
			alt: 'Disneyland Paris',
		},
		{
			id: 'empire-state-building',
			alt: 'The Empire State Building',
		},
		{
			id: 'mvsei-vaticani',
			alt: 'Mvsei Vaticani',
		},
		{
			id: 'at-the-top',
			alt: 'At The Top: Burj Khalifa',
		},
		{
			id: 'chateau-de-versailles',
			alt: 'Chateau de Versailles',
		},
		{
			id: 'ain-dubai',
			alt: 'Ain Dubai',
		},
		{
			id: 'motf',
			alt: 'Museum of the Future',
		},
		{
			id: 'yas-waterworld',
			alt: 'Yas Waterworld',
		},
		{
			id: 'sydney-opera-house',
			alt: 'Sydney Opera House',
		},
		{
			id: 'big-bus',
			alt: 'Big Bus',
		},
		{
			id: 'merlin-entertainments',
			alt: 'Merlin Entertainments',
		},
		{
			id: 'warner-bros-world',
			alt: 'Warner Bros World',
		},
		{
			id: 'madison-square-garden',
			alt: 'Madison Square Garden',
		},
		{
			id: 'fcb',
			alt: 'Football Club Barcelona',
		},
		{
			id: 'parques-reunidos',
			alt: 'Parques Reunidos',
		},
		{
			id: 'real-madrid',
			alt: 'Real Madrid',
		},
		{
			id: 'universal-orlando-resorts',
			alt: 'Universal Orlando Resorts',
		},
		{
			id: 'resorts-world-singapore',
			alt: 'Resorts World Singapore',
		},
		{
			id: 'ferrari-world',
			alt: 'Ferrari World',
		},
		{
			id: 'royal-collection-trust',
			alt: 'Royal Collection Trust',
		},
		{
			id: 'coop-culture',
			alt: 'Co-op culture',
		},
		{
			id: 'english-heritage',
			alt: 'English Heritage',
		},
		{
			id: 'museu-nacional-del-prado',
			alt: 'Museu Nacional del Prado',
		},
		{
			id: 'historic-royal-palaces',
			alt: 'Historic Royal Palaces',
		},
		{
			id: 'belvedere',
			alt: 'Belvedere',
		},
		{
			id: 'uffizi-gallery',
			alt: 'Uffizi Gallery',
		},
		{
			id: 'duomo-di-milano',
			alt: 'Duomo di Milano',
		},
		{
			id: 'ocean-park',
			alt: 'Ocean Park',
		},
		{
			id: 'xoaret-mexico',
			alt: 'Xoaret Mexico',
		},
		{
			id: 'dubai-parks-and-resorts',
			alt: 'Dubai Parks and Resorts',
		},
	],
};

export const HEADOUT_HELP_NUMBERS = {
	AUS: '+61370663969',
	USA: '+1 347 897 0100',
	FR: '+33755537735',
	UK: '(+44) 117 325 5305',
	ES: '+34931227339',
	UAE: '+9718000321171',
	SG: '+6531580736',
	MY: '+601548770486',
	PL: '+48729087896',
	IT: '+39 02 8689 1704',
	PT: '+351300057144',
};

export const COUPON_TYPE = {
	BNGN: 'BNGN',
};

export const GRAND_PRIX = {
	BAHRAIN_GP: {
		TGID: '20014',
		BANNER_IMAGE_URL: {
			DESKTOP:
				'https://cdn-s3.headout.com/assets/images/banner/BahrainGP-hires-desktop.jpg',
			MOBILE: 'https://cdn-s3.headout.com/assets/images/banner/BahrainGP-hires-mobile.jpg',
		},
	},
	SAUDI_GP: {
		TGID: '20157',
		BANNER_IMAGE_URL: {
			DESKTOP:
				'https://cdn-s3.headout.com/assets/images/banner/SaudiGP-hires-desktop.jpg',
			MOBILE: 'https://cdn-s3.headout.com/assets/images/banner/SaudiGP-hires-mobile.jpg',
		},
	},
	ALL_TGIDS: [
		'20014',
		'20157',
		'20015',
		'20018',
		'19708',
		'20158',
		'20016',
		'20017',
		'12041',
		'12040',
		'20159',
		'19873',
		'20021',
		'20026',
		'20160',
		'20161',
		'17637',
		'17292',
		'20949',
		'19877',
		'6735',
	],
};

export const HOME_PAGE_VIDEO_BANNER_DWEB =
	'https://cdn-imgix.headout.com/media/videos/42bc24e61f0e32e3fbbefb0cbedbc961-Global%20Banner%20Video%20Desktop%20Version.mp4';

export const HOME_PAGE_VIDEO_BANNER_MWEB =
	'https://cdn-imgix.headout.com/media/videos/6303cabfd424e79083f458a3fddb367f-Global%20Banner%20Video%20Mobile%20Version.mp4';

export const HOME_PAGE_FALLBACK_BANNER_DWEB =
	'https://cdn-imgix.headout.com/media/images/214bb484bb33d864250442817c7a14e8-default_1.1.1.jpg';

export const HOME_PAGE_FALLBACK_BANNER_MWEB =
	'https://cdn-imgix.headout.com/media/images/102cf59506488691a790d5f65b6ac093-default_1.1.1.jpg';

export const HEART_URL =
	'https://cdn-imgix-open.headout.com/home/lottie-json/heart.json';

export const CASHBACK_COIN_ANIMATION_URL =
	'https://cdn-s3.headout.com/assets/lottie/cashbackCoinAnimation.json';

export const FLAGS_FOLDER_URL =
	'https://cdn-imgix-open.headout.com/home/country-flags/4x3';

export const WHY_HEADOUT_LIST_DATA = [
	{
		color: '#00C4EB',
		url: 'https://cdn-imgix-open.headout.com/home/lottie-json/gemstone.json',
	},
	{
		color: '#088943',
		url: 'https://cdn-imgix-open.headout.com/home/lottie-json/money.json',
	},
	{
		color: '#E5006E',
		url: HEART_URL,
	},
	{
		color: '#FFF266',
		url: 'https://cdn-imgix-open.headout.com/home/lottie-json/sunglasses.json',
	},
];

export const BLANK_PLACEHOLDER = ' ';

export const ADYEN = {
	SUPPORTED_LOCALES: {
		en: 'en-US',
		es: 'es-ES',
		fr: 'fr-FR',
		de: 'de-DE',
		it: 'it-IT',
		nl: 'nl-NL',
		pt: 'pt-PT',
		ar: 'ar',
		ko: 'ko-KR',
		pl: 'pl-PL',
	},
	FIELD_TYPE: {
		CARD_NUMBER: 'encryptedCardNumber',
		EXPIRY_DATE: 'encryptedExpiryDate',
		EXPIRY_MONTH: 'encryptedExpiryMonth',
		EXPIRY_YEAR: 'encryptedExpiryYear',
		CVV: 'encryptedSecurityCode',
	},
	THREE_DS_SANDBOX_URL: 'https://test.adyen.com/hpp/3d/validate.shtml',
	NATIVE_3DS: 'threeDS2',
	PLACEHOLDER_LABELS: {
		'creditCard.numberField.placeholder': CARD_NUMBER_PLACEHOLDER,
		'creditCard.cvcField.placeholder.4digits': '',
		'creditCard.cvcField.placeholder.3digits': '',
	},
	EMPTY_PLACEHOLDERS: {
		'creditCard.numberField.placeholder': BLANK_PLACEHOLDER,
		'creditCard.expiryDateField.placeholder': BLANK_PLACEHOLDER,
		'creditCard.cvcField.placeholder.4digits': BLANK_PLACEHOLDER,
		'creditCard.cvcField.placeholder.3digits': BLANK_PLACEHOLDER,
	},
	ANALYTICS_FIELD_NAME: {
		encryptedCardNumber: 'Card Number',
		encryptedExpiryDate: 'Expiry',
		encryptedSecurityCode: 'CVV',
	},
	ENV: {
		LIVE: 'live',
		TEST: 'test',
		ODE: 'odeadyen',
	},
};

export const SEARCH_ENTITY_TYPE = {
	CITY: 'city',
	PRODUCT: 'product',
	COLLECTION: 'collections',
};

export const SEARCH_INDEX_SETTINGS = {
	CITY: {
		searchableAttributes: [
			'displayName',
			'country.displayName',
			'timeZone',
		],
		hitsPerPage: 1,
		isLocationSpecificSearchResults: true,
	},
	PRODUCT: {
		searchableAttributes: ['name', 'summary', 'highlights'],
		hitsPerPage: 3,
		isLocationSpecificSearchResults: false,
	},
	COLLECTION: {
		searchableAttributes: ['displayName'],
		hitsPerPage: 2,
		isLocationSpecificSearchResults: false,
	},
};

export const MAX_RESULTS_SEARCH_DROPDOWN = 6;

export const UNIVERSAL_SEARCH_ID = 'universal-search-input';

export const DESKTOP_HEADER_CLASSNAME = 'header-wrapper';

export const DESKTOP_HEADER_Z_INDEX = 100;

export const HOME_PAGE_CONTENT_START_ID = 'home-page-content-start';

export const EXPERIENCE_PAGE_CONTENT_START_ID = 'experience-page-content-start';
export const CITY_PAGE_CONTENT_START_ID = 'city-page-content-start';

export const LIVE_PRICING = {
	SUPPORTED: 'YES',
	CHECKOUT_ONLY: 'CHECKOUT_ONLY',
	PRICE_CHANGED: 'pricing-change-success',
};

export const SORT_TYPE_RECENTLY_VIEWED = 'sort-type=RECENTLY_VIEWED';

export const DOCUMENT_READY_STATES = {
	LOADING: 'loading',
	INTERACTIVE: 'interactive',
	COMPLETE: 'complete',
};

export const SEATMAP_DATE_TIME_DRAWER_ANIMATION_TIME = 300;

export const CONFIRMATION_PAGE_EXPERIMENT_CITY_CODES = [
	'ROME',
	'PARIS',
	'BARCELONA',
	'LONDON',
	'DUBAI',
	'FLORENCE',
	'MADRID',
	'VENICE',
	'NAPLES',
	'LISBON',
];

export const COMBO_UPSELL_TGID_TID_MAP: { [key: string]: string | string[] } = {
	'3437': '5310',
	'1866': '2638',
	'6732': '17521',
	'5730': '10294',
	'9651': '17458',
	'8541': '15250',
	'6235': '11325',
	'7563': '13770',
	'12045': ['23110', '46004'],
	'14126': '26842',
	'17655': '33676',
	'3394': '5251',
};

export const COMBO_UPSELL_NON_FEE_TGIDS = [
	'3437',
	'1866',
	'6732',
	'5730',
	'12045',
	'14126',
	'3394',
];

export const BLIMP_ANIMATION_LOTTIE_URL = `https://cdn-s3.headout.com/assets/lottie/blimpAnimation.json`;

export const COMBO_UPSELL_ANIMATION_LOTTIE_URL = `https://cdn-s3.headout.com/assets/lottie/comboUpsell.json`;

export const CONFUSED_DINO_LOTTIE_URL = `https://cdn-s3.headout.com/assets/lottie/confusedDinoLottie.json`;

export const F1_EXPERIENCE_DATE_MAP = {
	'6735': '2023-11-24',
	'17292': '2023-09-15',
	'19877': '2023-10-27',
	'20021': '2023-07-21',
	'20026': '2023-07-28',
	'20160': '2023-08-25',
	'20161': '2023-08-26',
	'20949': '2023-10-20',
	'21071': '2023-09-01',
	'23520': '2023-07-30',
};
export const UAE_COUNTRY_CODE = 'ae';
export const COLLECTIONS_LIMIT = {
	PLACES_TO_VISIT: 100,
	GLOBAL_HOMEPAGE: 50,
	DEFAULT: 20,
};

export const PRODUCT_LIST_LIMIT = {
	DEFAULT: 30,
};

export const APP_PUSH_PAGE = {
	FALLBACK_DEEPLINK: 'https://headout.app.link/appPushFallback',
	COUPON_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/coupon.svg',
	FLASH_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/flash.svg',
	HEART_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/heart.svg',
	QR_SCANNER_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/app-push-page-qr.png',
	STORE_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/stores.svg',
	HEART_EYES_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/heart-eyes.svg',
	TICKET_SCREEN_ILLUSTRATION_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/ticket-screen.png',
};

export const BUTTON_LOADING_THRESHOLD_TIME = 45000;

export const SITEMAP_PAGE_LIMIT = 102;

export const CITIES_SITEMAP_FILTERS = [
	{ label: 'TOP_100', urlParam: '', apiParam: '' },
	{ label: 'ASIA', urlParam: 'asia', apiParam: 'ASIA' },
	{ label: 'AFRICA', urlParam: 'africa', apiParam: 'AFRICA' },
	{ label: 'EUROPE', urlParam: 'europe', apiParam: 'EUROPE' },
	{
		label: 'NORTH_AMERICA',
		urlParam: 'north-america',
		apiParam: 'NORTH_AMERICA',
	},
	{
		label: 'SOUTH_AMERICA',
		urlParam: 'south-america',
		apiParam: 'SOUTH_AMERICA',
	},
	{
		label: 'OCEANIA',
		urlParam: 'oceania',
		apiParam: 'OCEANIA',
	},
];

export const COLLECTIONS_SITEMAP_FILTERS = [
	{ label: 'TOP_100', urlParam: '' },
	{ label: 'A-E', urlParam: 'a-e' },
	{ label: 'F-K', urlParam: 'f-k' },
	{ label: 'L-P', urlParam: 'l-p' },
	{
		label: 'Q-U',
		urlParam: 'q-u',
	},
	{
		label: 'V-Z',
		urlParam: 'v-z',
	},
];

export const FPX_RESULT_CODE = {
	AUTHORISED: 'authorised',
	REFUSED: 'refused',
	CANCELLED: 'cancelled',
	ERROR: 'error',
};

export const FPX_FAILURE_CASES = [
	FPX_RESULT_CODE.REFUSED,
	FPX_RESULT_CODE.CANCELLED,
	FPX_RESULT_CODE.ERROR,
];

export const GDPR_COUNTRY_CODES = [
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'HU',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'ES',
	'SE',
	'GB',
	'CH',
	'BH',
	'IL',
	'QA',
	'TR',
	'KE',
	'MU',
	'NG',
	'ZA',
	'UG',
	'JP',
	'KR',
	'NZ',
	'AR',
	'UY',
];

export const PLACES_TO_VISIT_SLUGS: Record<string, string> = {
	EN: 'places-to-visit',
	ES: 'lugares-para-visitar',
	FR: 'lieux-a-visiter',
	DE: 'sehenswuerdigkeiten',
	NL: 'bezienswaardigheden',
	PT: 'lugares-para-visitar',
	IT: 'luoghi-da-visitare',
};

export const ERROR_MESSAGES = Object.freeze({
	UNKNOWN_IMAGE_SRC_RENDERED: 'Unknown Image Source Rendered',
	LIVE_PRICE_CHECK_FAILED: 'Live Price Check Failed',
	USER_ABORTED: 'The user aborted a request.',
});

export const RIV_CHAIR_ANIMATION =
	'https://cdn-imgix.headout.com/assets/rive/seatLoader.riv';

export const HARDCODED_BILINKS = {
	'14128':
		'JTdCJTIydCUyMiUzQSUyMkV4dHJhJTIwMTUlMjUlMjBPZmYlMjBvbiUyMFlvdXIlMjBGaXJzdCUyMFB1cmNoYXNlJTIwb2YlMjBWaWV3JTIwQXQlMjBUaGUlMjBQYWxtJTIwVGlja2V0cyUyMiUyQyUyMmRlc2MlMjIlM0ElMjJVc2UlMjBjb2RlJTIwRlJFU0gxNSUyMHVwb24lMjBjaGVja291dC4lMjBIdXJyeSUyQyUyMGxpbWl0ZWQlMjB0aW1lJTIwb2ZmZXIlMjBvbmx5LiUyMiUyQyUyMmMlMjIlM0ElMjJGUkVTSDE1JTIyJTJDJTIydHlwZSUyMiUzQSUyMlBST01PX01BTlVBTCUyMiUyQyUyMndpbGRDYXJkJTIyJTNBJTIyREFJTFklMjIlN0Q%3D',
};

export const BROADWAY_SEAT_SELECTION_VISUALIZATION = [
	10017, 11340, 1293, 19633, 23939, 24671, 24920, 25023, 519, 5838, 10069,
	11845, 17676, 19636, 24533, 24863, 24929, 512, 5806, 730, 508, 507, 24867,
	25023, 25380, 25633, 25673, 25746, 25747, 25949, 26404, 26501,
];

export const BRAND_EXPERIMENT_EXEMPTED_CITIES = ['ROME', 'PARIS', 'DUBAI'];

export const MICROSOFT_LCID_MAP: { [key: string]: number } = {
	en: 1033,
	es: 1034,
	fr: 1036,
	it: 1040,
	de: 1031,
	nl: 1043,
};

export const BI_LINK_URL =
	'https://cdn-s3.headout.com/bilink-tgid-config/bilink-tgid-map.json';

export const CTA_TYPE = {
	CANCEL: 'Cancel',
	BACK: 'Back',
	CONFIRM: 'Confirm',
	SELECT: 'Select',
};

export const SHORTER_CACHE_AGE = 10;

export const LOCALHOST = 'http://localhost:4000';

export const COOKIE_DELIMITER = '-';

export enum broadwaySectionNames {
	ORCH = 'orchestra',
	FMEZ = 'frontmezzanine',
	RMEZ = 'rearmezzanine',
	MMEZ = 'midmezzanine',
	MEZ = 'mezzanine',
	BALC = 'balcony',
	DRESS = 'dresscircle',
}

export const PAYMENT_METHODS_CONTAINER_ID = 'payment-methods-wrapper';

export const APP_VENDOR_IMAGE_HEIGHT = 45;
export const APP_VENDOR_IMAGE_WIDTH = 140;
export const FOOTER_CONTACT_ICON_DIM = 12;
export const DOWNLOAD_APP_QR_DIM = 84;

export const DEFAULT_IMAGE_DENSITY = 1.5;

export const MULTI_SELECTION_FILTER_ANIMATION_TIMEOUT = 315;

export const TICKET_CATEGORY_PAGE_SUBCATEGORIES_SECTION_INITIAL_FETCH_COUNT = 3;

export const TICKET_CATEGORY_ID = 1;

export const TOUR_PROPERTIES = {
	LANG: 'LANGUAGE_CODE',
};

export const GIF_LOADER_URL = `https://${CDN_IMGIX_BASE_SERVER}/assets/gif/headoutGifLoader.gif`;

export const GIF_LOADER_WHITE_BG_URL = `https://${CDN_IMGIX_BASE_SERVER}/assets/gif/headoutGifLoaderWhiteBg.gif`;

export const COLLECTION_DESC_TYPE = {
	COLLECTION_LANDING_PAGE: 'COLLECTION_LANDING_PAGE',
	GENERAL: 'GENERAL',
};

export const SIGNED_OUT_USER_IMG_SRC = `https://${CDN_IMGIX_BASE_SERVER}/assets/images/credits/SignedOutUser.png`;

export const MANAGE_BOOKING_VIEW_CREDITS = 'manage_booking_view_credits';

export const BOOKING_STAGES: {
	PAX_SELECTION: string;
	TIME_SELECTION: string;
} = {
	PAX_SELECTION: 'PAX_SELECTION',
	TIME_SELECTION: 'TIME_SELECTION',
};

export const CHECKOUT_DATE_TIME_DELIMITER = '•';

export const COUPON_ERROR = {
	TITLE: 'Coupon Error',
	LOGIN_ERROR_CODE: 'CAL-0408',
};

export const DISABLE_SELECT_PAGE_COLLECTIONS = [1011, 1019];
export const DISABLE_SELECT_PAGE_TGIDS = ['25089', '2449'];

export const PAYPAL_SDK = 'PAYPAL_SDK';

export const FEE_LABELS = {
	CANCELLATION_INSURANCE_FEE: 'cancellation insurance fee',
	CANCELLATION_INSURANCE: 'cancellation insurance',
	CONVENIENCE_FEE: 'convenience fee',
	HELIPORT_FEE: 'heliport fee',
	PRIORITY_BOARDING: 'priority boarding',
	SERVICE_CHARGE: 'service charge',
	SERVICE_TAX: 'service tax',
	TAX: 'tax',
	VAT: 'value added tax (vat)',
	VENDOR_SERVICE_FEE: 'vendor service fee',
	INTREPID_MEUSEUM: 'intrepid museum',
	SEAT_RESERVATION_FEE: 'seat reservation fee',
	FEE: 'fee',
};

// this is also getting used to control the checkout screen experiment
export const EXCLUDED_SUBCAT_IDS_DWEB_SELECT_PAX_EXP = [1019, 1011];

//#TODO: can be moved to the backend if we get more such requests
export const SKIP_FLEXI_DESCRIPTOR_LIST = [
	2895, 4851, 7312, 25078, 26765, 26612, 28318,
];

export const customEvents = {
	ADTEN_CHALLENGE_MODAL_OPEN: 'adyen::challengeModal:open',
} as const;

export const CONFETTI_LOTTIE = `https://tourlandish.s3.amazonaws.com/assets/lottie/confetti.json?fslkafjd`;

export const LANDMARK_MAP_MARKER_URL =
	'https://cdn-imgix-open.headout.com/itinerary/map/pass-by-landmark.svg';

export const POI_ICON_MAP: { [key: string]: string } = {
	24: 'https://cdn-imgix.headout.com/assets/svg/poi/toilets.svg',
	25: 'https://cdn-imgix.headout.com/assets/svg/wifi.svg',
	90: 'https://cdn-imgix.headout.com/assets/svg/info.svg',
	21: 'https://cdn-imgix.headout.com/assets/svg/shopping.svg',
	33: 'https://cdn-imgix.headout.com/assets/svg/accessibility.svg',
	32: 'https://cdn-imgix.headout.com/assets/svg/accessibility.svg',
	34: 'https://cdn-imgix.headout.com/assets/svg/stroller.svg',
	1: 'https://cdn-imgix.headout.com/assets/svg/poi/cloak-room.svg',
	4: 'https://cdn-imgix.headout.com/assets/svg/poi/nursing-room.svg',
	5: 'https://cdn-imgix.headout.com/assets/svg/pets.svg',
	6: 'https://cdn-imgix.headout.com/assets/svg/poi/restraurant.svg',
	7: 'https://cdn-imgix.headout.com/assets/svg/poi/first-aid.svg',

	info: 'https://cdn-imgix.headout.com/assets/svg/info.svg',
};

export const MARKER_PIN = {
	BY_BUS: 'https://cdn-imgix.headout.com/assets/svg/bus-pin.svg',
	BY_METRO: 'https://cdn-imgix.headout.com/assets/svg/train-pin.svg',
	BY_TRAM: 'https://cdn-imgix.headout.com/assets/svg/train-pin.svg',
	BY_TRAIN: 'https://cdn-imgix.headout.com/assets/svg/train-pin.svg',
	BY_CAR: 'https://cdn-imgix.headout.com/assets/svg/parking-pin.svg',
};

export const LANG_ICON_ID_MAP: {
	[k: string]: { [key: string]: number };
} = {
	enLabelMap: {
		'Souvenir shop': 21,
		Toilets: 24,
		Wifi: 25,
		ATMs: 29,
		'Wheelchair available': 32,
		'Wheelchair accessible': 33,
		'Strollers allowed': 34,
		'Transportation services': 89,
		Videoguides: 90,
		'Shower rooms': 98,
		'Cloak room': 1,
		'Baby nursing rooms': 4,
		'Service animals allowed': 5,
		'Dining outlets': 6,
		'First aid': 7,
	},
	deLabelMap: {
		Souvenirladen: 21,
		Toiletten: 24,
		WLAN: 25,
		Geldautomaten: 29,
		'Rollstuhl verfügbar': 32,
		Rollstuhlgerecht: 33,
		'Kinderwagen erlaubt': 34,
		Transferdienste: 89,
		Videoguides: 90,
	},
	esLabelMap: {
		'Tienda de recuerdos': 21,
		Aseos: 24,
		Wifi: 25,
		'Cajeros automáticos': 29,
		'Silla de ruedas disponible': 32,
		'- El palacio es accesible para sillas de ruedas.': 33,
		'Se admiten cochecitos': 34,
		'Servicios de transporte': 89,
		Videoguías: 90,
	},
	frLabelMap: {
		'Boutique de souvenirs': 21,
		Toilettes: 24,
		WiFi: 25,
		DAB: 29,
		'Fauteuil roulant disponible': 32,
		'Accessible en fauteuil roulant': 33,
		'Poussettes autorisées': 34,
		'Services de transport': 89,
		Vidéoguides: 90,
	},
	itLabelMap: {
		'Negozio di souvenir': 21,
		'Servizi igienici': 24,
		'Wi-Fi': 25,
		Bancomat: 29,
		'Sedia a rotelle disponibile': 32,
		'Accessibile alle sedie a rotelle': 33,
		'Passeggini ammessi': 34,
		'Servizi di trasporto': 89,
		Videoguide: 90,
	},
	nlLabelMap: {
		Souvenirwinkel: 21,
		Toiletten: 24,
		Wifi: 25,
		Geldautomaten: 29,
		'Rolstoel beschikbaar': 32,
		'Toegankelijk voor rolstoelen': 33,
		'Kinderwagens toegestaan': 34,
		Transportdiensten: 89,
		Videogidsen: 90,
	},
	ptLabelMap: {
		'Loja de souvenir': 21,
		Banheiros: 24,
		'Wi-Fi': 25,
		'Caixa eletrônico': 29,
		'Cadeira de rodas disponível': 32,
		'Acessível para cadeirantes': 33,
		'Carrinhos de bebê permitidos': 34,
		'Serviços de transporte': 89,
		Videoguias: 90,
	},
};

export const HOHO_V2_ALLOWED_CITIES = [
	'BARCELONA',
	'PARIS',
	'ROME',
	'LONDON',
	'DUBAI',
	'VIENNA',
	'NEW_YORK',
	'SINGAPORE',
	'MADRID',
	'VALENCIA',
	'EDINBURGH',
	'AUCKLAND',
];

export const HOHO_V2_ALLOWED_CITIES_FOR_HOHO_V2_SELECT_PAGE_DWEB_REVAMP = [
	'SINGAPORE',
	'PARIS',
	'KUALA_LUMPUR',
	'BARCELONA',
	'LONDON',
	'SYDNEY',
	'DUBAI',
	'NEW_YORK',
	'ROME',
	'TOKYO',
];

// base root font at 100%
export const BASE_ROOT_FONT_SIZE = 16;

export const COLLECTION_CARD_RANK_FONTS = [
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/1.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/2.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/3.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/4.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/5.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/6.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/7.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/8.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/9.svg',
	'https://cdn-imgix.headout.com/assets/svg/rank-bold-purps/10.svg',
];

export const CITY_COLLECTION_SORT_TYPES = {
	POPULAR: 'POPULAR',
};

export const TOFU_M2_CITY_PAGE_EXP_CITY_CODES = ['SINGAPORE', 'ROME'];
export const TOFU_M3_CITY_PAGE_EXP_CITY_CODES = ['PARIS', 'DUBAI'];

export const TOFU_CITY_PAGE_EXP_LANG_CODES = ['en'];

type CityCollectionData = {
	displayName: string;
	collectionId: string;
	cityCode: string;
	rank: string;
};

export const TOFU_CITY_PAGE_COLLECTIONS_DATA: Map<
	string,
	CityCollectionData[]
> = new Map([
	[
		'DUBAI',
		[
			{
				displayName: 'Best-kept secrets, shhh...',
				collectionId: '5835',
				cityCode: 'DUBAI',
				rank: '',
			},
			{
				displayName: "Eat like no one's watching",
				collectionId: '5856',
				cityCode: 'DUBAI',
				rank: '',
			},
		],
	],
	[
		'ROME',
		[
			{
				displayName: 'Best-kept secrets, shhh...',
				collectionId: '5857',
				cityCode: 'ROME',
				rank: '',
			},
			{
				displayName: 'Day trips from Rome',
				collectionId: '5858',
				cityCode: 'ROME',
				rank: '',
			},
		],
	],
]);

export const CITY_PAGE_PRODUCT_FEED_FETCH_LIMIT = 20;
export const CITY_PAGE_APIS_INITIAL_OFFSET = 0;

export const DEFAULT_REVIEWER_NAME = 'headout guest';

export const DEFAULT_TOP_REVIEWS_COUNT = 5;

export const CoralogixLogSeverity: any = {
	DEBUG: 1,
	VERBOSE: 2,
	INFO: 3,
	WARNING: 4,
	ERROR: 5,
	CRITICAL: 6,
};

export const HOHO_ORDERED_ATTRACTIONS: Record<number, string[]> = {
	3436: [
		'Sagrada Familia',
		'Park Güell',
		'La Rambla',
		'La Pedrera',
		'Casa Batlló',
		'Passeig de Gràcia',
		'Picasso Museum',
		'Arc de Triomf',
		'Parc de la Ciutadella',
		'Montjuïc Hill',
		"Museu Nacional d'Art de Catalunya",
		'Poble Espanyol',
		'Camp Nou FC Barcelona',
		'Fundació Joan Miró',
		'Tibidabo',
	],
	7677: [
		'Sagrada Família',
		'Park Güell',
		'La Rambla',
		'La Pedrera',
		'Casa Batlló',
		'Passeig de Gràcia',
		'Plaça de Catalunya',
		'Arc de Triomf',
		'Montjuïc Hill',
		"Museu Nacional d'Art de Catalunya",
		'Poble Espanyol',
		'Fundació Joan Miró',
		'Castell de Montjuïc',
		'Tibidabo',
		'Palau Reial – Pavellons Güell',
	],
	9774: [
		'Eiffel Tower',
		'Louvre Museum',
		'Notre Dame Cathedral',
		'Champs-Elysées',
		'Arc de Triomphe',
		"Musée d'Orsay",
		'Sainte-Chapelle',
		'Opéra Garnier',
		'Pantheon',
		'Pont des Arts',
		'Jardin du Luxembourg',
		'Jardin des Tuileries',
		'Palais Royal',
		'Galeries Lafayette',
		'Palais de Tokyo',
	],
	26765: [
		'Eiffel Tower',
		'Louvre Museum',
		'Notre Dame',
		'Arc de Triomphe',
		"Musée d'Orsay",
		'Sacré-Cœur Basilica',
		'Champs-Elysées',
		'Montmartre',
		'Opéra Garnier / Palais Garnier',
		'Place de la Concorde',
		'Trocadéro',
		'Pont Alexandre III',
		'Place Vendôme',
		'Palais Royale',
		'Musée Picasso',
	],
	983: [
		'Buckingham Palace',
		'London Eye (Eastbound)',
		'Tower of London',
		'Palace of Westminster',
		'Big Ben',
		"St. Paul's Cathedral",
		'Trafalgar Square',
		'Piccadilly Circus',
		'Madame Tussauds',
		'Covent Garden Market',
		'The British Museum',
		'Regent Street',
		'Hyde Park',
		'Leicester Square',
		'London Bridge',
	],
	19791: [
		'Buckingham Palace',
		'Big Ben',
		'Tower of London',
		'St Paul’s Cathedral',
		'London Bridge',
		'Trafalgar Square',
		'Borough Market',
		'SEA LIFE London Aquarium',
		'Somerset House',
		'Victoria Station',
		'Victoria Palace Theatre',
		'The Gherkin',
		'Marble Arch (Park Lane)',
		"Marble Arch (Speaker's Corner)",
		'The London Dungeon',
	],
	10297: [
		'Colosseum',
		'Vatican City - Sistine Chapel & St. Angelo Bridge',
		'Trevi Fountain',
		'Foro Romano',
		'Palatine Hill',
		'Piazza Venezia',
		'Spanish Steps',
		'Circo Massimo',
		'St. Mary Major',
		'Basilica Santa Maria Maggiore',
		'St. Angelo Bridge',
		'Bocca della Verità',
		'Palazzo del Quirinale',
		'National Roman Museum',
		'Keats-Shelley Memorial House',
	],
	9646: [
		'Colosseum',
		'Vatican',
		'Spanish Steps',
		'Palatine Hill',
		'Piazza Venezia',
		"Castel Sant'Angelo",
		'Circo Massimo',
		'Basilica Santa Maria Maggiore',
		'Bocca della Verità',
		'Palazzo del Quirinale',
		'National Roman Museum',
		'Teatro di Marcello',
		'Santa Maria della Concezione dei Cappuccini',
		'Piazza Barberini',
		'Via Condotti',
	],
};

export const HOME_CURRENCY_COUNTRY_LIST = [
	'US',
	'GB',
	'FR',
	'DE',
	'IT',
	'ES',
	'PT',
];

export const EXTENDED_REVIEWS_ELIGIBLE_COLLECTIONS_IDS = [
	110, 111, 253, 254, 325, 382, 575, 1178, 1293, 1509, 1537, 1816, 1892, 1894,
	2310, 3746, 4318, 4622, 4973,
];

export const EXTENDED_REVIEWS_ELIGIBLE_CATEGORY_IDS_BY_CITY = {
	6682: [18],
	8325: [18],
};

export const EXTENDED_REVIEWS_ELIGIBLE_SUBCATEGORY_IDS_BY_CITY = {
	6: [1049],
	7426: [1011],
	6280: [1011],
};

export const EXTENDED_REVIEWS_ELIGIBLE_CITIES = [
	'DUBAI',
	'PARIS',
	'SINGAPORE',
	'BUDAPEST',
	'LISBON',
	'ISTANBUL',
	'ATHENS',
	'VENICE',
	'BUCHAREST',
	'NAPLES',
	'BANGKOK',
];
export const CITY_TRAVEL_GUIDE_BG_BANNER_URLS: {
	[k: string]: string;
} = {
	DUBAI: 'https://cdn-imgix.headout.com/media/images/8b72941ffa3bbdff893b4cc935425211-City%3DDubai.svg',
	PARIS: 'https://cdn-imgix.headout.com/media/images/e47f8fa27fb9474cc493c8efa7fe95a6-Paris-content-page-banner.svg',
	SINGAPORE:
		'https://cdn-imgix.headout.com/media/images/d9f1d1c7b63c1709fdd959ba91a54fed-City%3DSingapore.svg',
	ROME: 'https://cdn-imgix.headout.com/media/images/71bc27aeda4a13c0da89803f4e2082af-City-Rome.svg',
};

export const CITY_BLOGS_DATA: {
	[k: string]: {
		image: {
			url: string;
			alt: string;
		};
		title: string;
		url: string;
		subText: string;
	}[];
} = {
	DUBAI: [
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/d2d864342d133551e45bd28603f94b3b-skyviews%20banner.jpg',
				alt: 'blog',
			},
			url: 'https://www.headout.com/blog/top-observation-decks-dubai/',
			title: 'Observation decks',
			subText:
				"Dubai is a city of wonders, where modern meets traditional, and old-world glamour meets cutting-edge opulence. It's no surprise that some of the best views in Dubai can be found just about everywhere you turn.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/tour/29579/TOUR-IMAGE/28442eb1-4d34-4306-bd84-31002339229b-15810-dubai-ski-dubai--snow-daycation-pass-01-3-.jpg',
				alt: 'blog',
			},
			url: 'https://www.headout.com/blog/beat-the-heat-dubai/',
			title: 'Beat the heat',
			subText:
				'Dubai is a mesmerizing desert city, where soaring temperatures during the hot months can be quite a challenge. As the mercury rises, locals and tourists alike seek refuge from the scorching sun in various indoor attractions and cool destinations throughout the city. ',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/820b1fc14e3c14f074b7a6789f41f3d4-Shopping%20in%20Dubai.jpg',
				alt: 'blog',
			},
			title: 'Shoppers paradise',
			url: 'https://www.headout.com/blog/shopping-in-dubai/',
			subText:
				'Dubai is a city of luxury and extravagance, and nowhere is this more evident than in its retail therapy experiences. From high-end designer boutiques to traditional Souks, Dubai offers a shopping experience like no other.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/tour/16393/TOUR-IMAGE/1005edd4-6ca3-4c35-8650-ae756feed694-9088-dubai-laguna-waterpark-04.jpg',
				alt: 'blog',
			},
			title: 'Dubai with kids',
			url: 'https://www.headout.com/blog/dubai-with-kids/',
			subText:
				"Welcome to the dazzling city of Dubai, a captivating destination that offers a multitude of adventures for both adults and children alike. If you're planning a family vacation, Dubai is the perfect choice, as it seamlessly combines entertainment, culture, and luxury.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/ece58f692d163e54c6df0898c8bc0b79-dubai%20palm%20hotel.jpg',
				alt: 'blog',
			},
			title: 'Travel guide',
			url: 'https://www.headout.com/blog/dubai-travel-guide/',
			subText:
				"Get ready to go on an epic adventure as I spill the beans on one of the most dazzling gems of the Middle East – Dubai! I've called this city home for quite some time, and let me tell you, it's a place that never ceases to amaze me.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/blog-content/unsplash/image/8c3a73cf8bd2eada721538a30cd9a0f9-zq-lee-DcyL0IoCY0A-unsplash.jpg',
				alt: 'blog',
			},
			title: 'Best time to visit',
			url: 'https://www.headout.com/blog/best-time-to-visit-dubai/',
			subText:
				'Get ready to uncover the key insights for an unforgettable Dubai experience! When you think of Dubai, you might picture golden deserts, towering skyscrapers (yes, the Burj Khalifa!), and delicious shawarma.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/9ed435ddd26ddcef33b8eea26774c982-dubai.jpg',
				alt: 'blog',
			},
			title: 'Tips',
			url: 'https://www.headout.com/blog/dubai-travel-tips/',
			subText:
				"Dubai's meteoric rise as a travel hotspot is nothing short of remarkable. Once barely a blip on travelers' radars, the UAE now boasts two world-class destinations, with Dubai leading the charge.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/blog-content/image/1af4fb7ce9fd23e02b05fcd2deb8e5f0-sid-balachandran-cUpp1gAEtiU-unsplash%20(1).jpg',
				alt: 'blog',
			},
			title: 'Day trips',
			url: 'https://www.headout.com/blog/best-day-trips-from-dubai/',
			subText:
				"If you've already soaked the sun in Dubai's many beaches, taken a dhow cruise on the Dubai Creek and climbed atop the world's tallest building - the Burj Khalifa, it's maybe time to head out of the glitzy city and discover what the other emirates have to offer.",
		},
	],
	PARIS: [
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/37eb5c984759cd3dc6b15b28031a1333-palacewhatis.jpg',
				alt: 'blog',
			},
			title: 'Emily in Paris locations',
			url: 'https://www.headout.com/blog/emily-in-paris-locations/',
			subText:
				"If you've ever dreamed of living your best Parisian life, Emily in Paris starring Lily Collins, has undoubtedly fueled your desire to visit the City of Love, Light, Fashion and Croissants.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/66745bb4a4daea95f97bea1d0b12369f-Arc-de-Triomphe-and-Christmas-Illuminations-on-Champs-Elysees%2C-Paris-in-France.jpg',
				alt: 'blog',
			},
			title: 'Christmas in Paris',
			url: 'https://www.headout.com/blog/christmas-in-paris/',
			subText:
				"If you've ever dreamed of living your best Parisian life, Emily in Paris starring Lily Collins, has undoubtedly fueled your desire to visit the City of Love, Light, Fashion and Croissants.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/blog-banner/image/d08251597af27f3ae6cbc7ef426dd566-the-paris-photographer-V1BANGpdi0E-unsplash.jpg',
				alt: 'blog',
			},
			title: 'Romantic things to do',
			url: 'https://www.headout.com/blog/romantic-things-to-do-in-paris/',
			subText:
				'Every travel brochure and blog waxes eloquent about Paris as the ‘City of Love,’ but no matter how clichéd the city deserves all the adulation.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/80463489a6c729734b161dfc0f647719-paris.jpg',
				alt: 'blog',
			},
			title: 'Best time to visit',
			url: 'https://www.headout.com/blog/best-time-to-visit-paris/',
			subText:
				"Thinking about strolling down the charming streets of Paris? Whether you're a first-timer or a seasoned traveler, deciding when to visit the City of Lights is key.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/blog-banner/image/9f047249e9d81282deff95f389268728-filip-mishevski-eZ2aHgA6Xds-unsplash.jpg',
				alt: 'blog',
			},
			title: 'Budget trip',
			url: 'https://www.headout.com/blog/paris-on-a-budget/',
			subText:
				"So, you've got this burning desire to experience the magic of Paris but without burning a hole in your pocket, right? Well, you've landed in the right place!",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/f693d03867bffb1f88a17d8ce5cbcdd1-24655-Paris-ParisCityPass-allwhatyouneed-HoponHopoff-60-Museumsandguidedtour-005.jpg',
				alt: 'blog',
			},
			title: 'Tips',
			url: 'https://www.headout.com/blog/top-things-to-know-before-visiting-paris/',
			subText:
				'Paris, often hailed as a dream destination, is a must-visit for its rich artistic heritage and myriad attractions. With over 45 million tourists flocking to Paris in 2023 there’s no reason why you shouldn’t be one of them?',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/tour/7310/TOUR-IMAGE/f53c2c7b-966e-4cf5-bf44-5fe38eb1e6d4-5030-Paris-Notre-02.jpg',
				alt: 'blog',
			},
			title: 'Travel guide',
			url: 'https://www.headout.com/blog/where-to-stay-in-paris/',
			subText:
				"Paris: a city that caters to all, from sightseers to shopaholics to families seeking a good vacation! When planning your Parisian adventure, location is key. You'll want to stay somewhere central, with easy access to major attractions and local culinary delights.",
		},
	],
	SINGAPORE: [
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/6a14cb832efc47d3cadb708ff5d8965c-25431-singapore-singapore-flyer-skip-the-line-tickets-with-premium-beverage-04.jpg',
				alt: 'blog',
			},
			title: 'Travel guide',
			url: 'https://www.headout.com/blog/singapore-travel-guide/',
			subText:
				'A melting pot of global culture, hanging out on an island off the southern coast of Malaysia is the city-state of Singapore, or the Lion City - epitomised in the spirit of the land and immortalised in its name.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/d2e9c3590e54f929186716d926bd6406-7371-singapore-ticket-to-marina-bay-sands-skypark-observation-deck-03.jpg',
				alt: 'blog',
			},
			title: 'Budget trip',
			url: 'https://www.headout.com/blog/singapore-budget-travel/',
			subText:
				"Ready to delve into the diverse and vibrant city of Singapore without breaking the bank? You're in the right place! In this guide, we'll show you how to make the most of your visit to Singapore while keeping your budget intact.",
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/d073849ac89ad90381c6038f6fc5cee6-Marina-By-Sands-Skypark.jpg',
				alt: 'blog',
			},
			title: 'Bucket list',
			url: 'https://www.headout.com/blog/singapore-bucket-list/',
			subText:
				'Often referred to as the Lion City, Singapore stands as the urban jewel of Southeast Asia. It is an amazing place for visitors of all ages, offering a wealth of activities, sights, and culinary experiences. This melting pot of cultures is especially renowned for its diverse and affordable culinary scene, featuring some of the best Indian and Chinese cuisine.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/blog-banner/image/496b861501f81f2703d980b0ea1e2a8e-jisun-han-cjSsSQ7Yedk-unsplash.jpg',
				alt: 'blog',
			},
			title: 'Best time to visit',
			url: 'https://www.headout.com/blog/best-time-to-visit-singapore/',
			subText:
				'Singapore needs no introduction. This ‘Lion City’ has made a splash in almost every corner of the world, with good reason. Located just off the southern tip of Malaysia, this uber cosmopolitan city-state boasts a beautiful blend of Malaysian, Indian, Chinese, Arab and English cultures – all with its own Singaporean twist.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/mircobrands-content/image/73b86a107513aea307d24faa8de4712f-Travel%20Essentials%20in%20Singapore.jpg',
				alt: 'blog',
			},
			title: 'Tips',
			url: 'https://www.headout.com/blog/singapore-travel-tips/',
			subText:
				'Singapore might seem pricey, but you can totally experience it on a budget. It’s one of the safest countries to visit with its own unique charm. You’ll be amazed by their focus on sustainability, especially at places like Gardens by the Bay. This quirky island city offers tons of exciting activities and unique experiences for every traveler.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/blog-banner/image/496b861501f81f2703d980b0ea1e2a8e-jisun-han-cjSsSQ7Yedk-unsplash.jpg',
				alt: 'blog',
			},
			title: 'Day trips',
			url: 'https://www.headout.com/blog/best-day-trips-from-singapore/',
			subText:
				"Cable car rides, adventure sports, kayaking among the mangroves, and beautiful ferry rides - you will find all this and more within a few hours' drive from the city. If you're looking for an adventure, take a day trip from Singapore to any of the destinations listed below.",
		},
	],
	ROME: [
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/c85d02b4183f4018f14a87eccd62f1b2-rome.jpg',
				alt: 'blog',
			},
			title: 'Travel guide',
			url: 'https://www.headout.com/blog/rome-travel-guide/',
			subText:
				'The ancient seat of the progenitors of Western Civilisation, Rome is a classic European city that is a mix of historic ruins, stunning works of art & architecture, great food and a vibrant city life making it one of the most beautiful and romantic cities in the world.',
		},
		{
			image: {
				url: 'https://cdn-imgix-open.headout.com/blog/l-a-l-a-s-z-a-S0hS0HfH_B8-unsplash.jpg',
				alt: 'blog',
			},
			title: 'Day trips',
			url: 'https://www.headout.com/blog/day-trips-from-rome/',
			subText:
				"Exploring Rome and its surroundings can be a lifelong journey, what with it being full of ancient sites, historic landmarks, and captivating ruins. If you're considering mixing up your stay in the Eternal City with a few day trips, you're in for a treat.",
		},
		{
			image: {
				url: 'https://www.headout.com/blog/wp-content/uploads/2019/01/Rome.jpg',
				alt: 'blog',
			},
			title: 'Best time to visit',
			url: 'https://www.headout.com/blog/best-time-to-visit-rome/',
			subText:
				'Known as the Eternal City for good reason, Rome offers timeless allure for visitors year-round (pun intended!). Its winters are mild, sparing travelers bone-chilling cold, while summers, though sweltering, provide a quintessentially European charm.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/01d9653245b12c8215c30093c544787f-10321-rome-city-sightseeing-rome--24-48-72-hr-hop-on-hop-off-bus-tour---free-audio-guide-02.jpg',
				alt: 'blog',
			},
			title: 'Tips',
			url: 'https://www.headout.com/blog/best-time-to-visit-singapore/',
			subText:
				'Rome is a big city. Unlike other great tourist cities in Europe where attractions are usually concentrated in the city center, or in surrounding neighborhoods, the landmarks of Rome are spread out throughout the city.',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/000bd87572e5d639ae4ae574023036e8-3361-rome-guided-tour-of-saint-peter-s-basilica-cupola-01.jpg',
				alt: 'blog',
			},
			title: 'Free things to do',
			url: 'https://www.headout.com/blog/free-things-to-do-in-rome/',
			subText:
				'Of the elite tourist destinations in Europe, Rome is one you don\'t want to miss. With its architectural brilliance and cultural heritage, however, it is not one for "budget travel" (or so you\'d think!) Evidently, there are many fascinating ways to explore the City of Seven Hills on a shoestring budget',
		},
		{
			image: {
				url: 'https://cdn-imgix.headout.com/media/images/2599f491f95631b34c47e0f6ec0fffba-Rome%20on%20a%20Budget.jpg',
				alt: 'blog',
			},
			title: 'Budget trip',
			url: 'https://www.headout.com/blog/rome-on-a-budget/',
			subText:
				'Rome is without doubt one of the most popular travel destinations in the world. Home to some of the globe’s most historic and emblematic monuments, it comes as no surprise that over 9 million people flock to the Italian capital, year after year, to get a taste of this age-old city steeped in culture and history.',
		},
	],
};

export const CITY_TRAVEL_GUIDE_VIDEO: {
	[k: string]: {
		videoUrl: string;
		thumbnailUrl: string;
		durationLabel: string;
	};
} = {
	DUBAI: {
		videoUrl:
			'https://cdn-imgix.headout.com/media/videos/98e6e199b65980eb3ad813e540bf8ea4-Dubai-Travel%20Guide-720p.mp4',
		thumbnailUrl:
			'https://cdn-imgix.headout.com/media/images/81c1d5a01903c5bec6375420668c6e06-Dubai-Video-Thumbnail.jpg',
		durationLabel: '10:31',
	},
	PARIS: {
		videoUrl:
			'https://cdn-imgix.headout.com/media/videos/20286e4f193c0f6a6aa610837b0e2ba2-Paris%20City%20Guide(Final%20File)%20(1).mp4',
		thumbnailUrl:
			'https://cdn-imgix.headout.com/media/images/beb151c611b1f8dc92ff5213ae314671-Paris-Video-Thumbnail.jpg',
		durationLabel: '9:43',
	},
	SINGAPORE: {
		videoUrl:
			'https://cdn-imgix.headout.com/media/videos/98a6b364e45e0cb066219d61358cf0a0-Singapore%20Travel%20Guide%20for%202024%20%20%20Things%20to%20do%20in%20SINGAPORE.mp4',
		thumbnailUrl:
			'https://cdn-imgix.headout.com/media/images/7d307330304c05d1ba7efca3b9ad00e3-singapore-video-thumbnail.jpg',
		durationLabel: '15:11',
	},
	ROME: {
		videoUrl:
			'https://cdn-imgix.headout.com/media/videos/7ba67cc46371fedd6da96d20275f3ec3-Rome%20City%20Guide%20720p%20(Final%20File).mov',
		thumbnailUrl:
			'https://cdn-imgix.headout.com/media/images/8355935b08f79b08b6d8e23d2e7c238c-rome-travel-guide.jpg',
		durationLabel: '11:21',
	},
};

export const FALLBACK_GUEST_COUNT = 32;

export const DEFAULT_LANG = 'en';

// Product Page Overlay Sections
export const REVIEW_SECTION_HASH = 'showReviews';
export const LOCATION_MAP_SECTION_HASH = 'showLocationMap';

export const SCARCITY_BOOSTER_TYPE: Record<
	string,
	'xTicketsLeft' | 'likelyToSellOut'
> = {
	X_TICKETS_LEFT: 'xTicketsLeft',
	LIKELY_TO_SELL_OUT: 'likelyToSellOut',
};

export const SENSITIVE_COLLECTION_IDS = [4426, 2659, 2660, 5320];
